import P5Sketch from "./P5Sketch";
import P5 from "p5";
//https://openprocessing.org/sketch/1257795
export default class ExampleSketch5 extends P5Sketch {

    constellation = [];
    n;
    d;

    setup(p5)
    {
        //what is super?
        // super.setup(p5);

        this.p5.createCanvas(1112, 834);
        this.p5.colorMode(this.p5.HSB, 255);
        this.p5.pixelDensity(1); // Set 1 because it's too slow on firefox
        let n = 200;

        for (let i = 0; i <= n; i++) {
            this.constellation.this.p5.push(new this.star());
        }
        this.p5.strokeWeight(1);
        this.p5.stroke('#00FFaF');
    }

    draw = (p5) => {
        ///// what is super?
        // super.draw(p5);


        this.p5.background('#000020');

        for (let i = 0; i < this.constellation.length; i++) {
            this.constellation[i].update();
            for (let j = 0; j < this.constellation.length; j++) {
                if (i > j) { // "if (i > j)" => to check one time distance between two stars
                    let d = this.constellation[i].loc.dist(this.constellation[j].loc); // Distance between two stars
                    if (d <= this.p5.width / 7) { // if d is less than width/7 px, we draw a line between the two stars
                        let hu = 2 * this.p5.dist(this.constellation[i].loc.x, this.constellation[i].loc.y, this.constellation[j].loc.x, this.constellation[j].loc.y);
                        this.p5.stroke(hu%255, 200, 255);
                        this.p5.line(this.constellation[i].loc.x, this.constellation[i].loc.y, this.constellation[j].loc.x, this.constellation[j].loc.y)
                    }
                }
            }
        }
    }

    onClick = () => {
        //onclick is from example2
        super.onClick();
    }


    //it's a class - can we do that?
    star() {

        let a = this.p5.random(5 * this.p5.TAU); // "5*TAU" => render will be more homogeneous
        let r = this.p5.random(this.p5.width * .2, this.p5.width * .15); // first position will looks like a donut
        let loc = this.p5.createVector(this.p5.width / 2 + this.p5.sin(a) * this.r, this.p5.height / 2 + this.p5.cos(a) * r);
        this.speed = this.p5.createVector();
        this.speed = this.p5.Vector.random2D();
        this.bam = this.p5.createVector();

        // I had to give it a value?
        this.m = 0;

    this.update = function() {
        this.bam = this.p5.Vector.random2D(); // movement of star will be a bit erractic
        this.bam.random2D();
        this.bam.mult(0.45);
        this.speed.add(this.bam);
        // speed is done according distance between loc and the mouse :
        this.m = this.p5.constrain(this.p5.map(this.p5.dist(this.loc.x, this.loc.y, this.p5.mouseX, this.p5.mouseY), 0, this.p5.width, 8, .05), .05, 8); // constrain => avoid returning "not a number"
        this.speed.normalize().mult(this.m);

        // No collision detection, instead loc is out of bound
        // it reappears on the opposite side :
        if (this.p5.dist(this.loc.x, this.loc.y, this.p5.width / 2, this.p5.height / 2) > (this.p5.width / 2) * 0.98) {
            if (this.loc.x < this.p5.width / 2) {
                this.loc.x = this.p5.width - this.loc.x - 4; // "-4" => avoid blinking stuff
            } else if (this.loc.x > this.p5.width / 2) {
                this.loc.x = this.p5.width - this.loc.x + 4; // "+4"  => avoid blinking stuff
            }
            if (this.loc.y < this.p5.height / 2) {
                this.loc.y = this.p5.width - this.loc.y - 4;
            } else if (this.loc.x > this.p5.height / 2) {
                this.loc.y = this.p5.width - this.loc.y + 4;
            }
        }
        this.loc = this.loc.add(this.speed);
    } // End of update()
} // End of class

    keyPressed(){
        // save("img_" + month() + '-' + day() + '_' + hour() + '-' + minute() + '-' + second() + ".jpg");
    }


}