class Star
{
    constructor(p5)
    {
        this.p5 = p5;

        this.a = this.p5.random(5 * this.p5.TAU); // "5*TAU" => render will be more homogeneous
        this.r = this.p5.random(this.p5.width * .4, this.p5.width * .25); // first position will looks like a donut
        this.loc = this.p5.createVector(this.p5.width / 2 + Math.sin(this.a) * this.r, this.p5.height / 2 + Math.cos(this.a) * this.r);
        this.speed = this.p5.createVector();
        this.speed = this.p5.Vector.random2D();
        //this.speed.random2D();
        this.bam = this.p5.createVector();
    }

    doTheThing(width, height)
    {
    }

    display(pos, particleSize)
    {
    }

    update(mouseX, mouseY)
    {
        this.bam = this.p5.Vector.random2D(); // movement of star will be a bit erractic
        //this.bam.random2D();
        this.bam.mult(0.45);
        this.speed.add(this.bam);
        // speed is done according distance between loc and the mouse :
        this.m = this.p5.constrain(this.p5.map(this.p5.dist(this.loc.x, this.loc.y, mouseX, mouseY), 0, this.p5.width, 8, .05), .05, 8); // constrain => avoid returning "not a number"
        this.speed.normalize().mult(this.m);

        // No colision detection, instead loc is out of bound
        // it reappears on the opposite side :
        if (this.p5.dist(this.loc.x, this.loc.y, this.p5.width / 2, this.p5.height / 2) > (this.p5.width / 2) * 0.98) {
            if (this.loc.x < this.p5.width / 2) {
                this.loc.x = this.p5.width - this.loc.x - 4; // "-4" => avoid blinking stuff
            } else if (this.loc.x > this.p5.width / 2) {
                this.loc.x = this.p5.width - this.loc.x + 4; // "+4"  => avoid blinking stuff
            }
            if (this.loc.y < this.p5.height / 2) {
                this.loc.y = this.p5.width - this.loc.y - 4;
            } else if (this.loc.x > this.p5.height / 2) {
                this.loc.y = this.p5.width - this.loc.y + 4;
            }
        }
        this.loc = this.loc.add(this.speed);
    }
}

export default class ExampleSketch16
{
    //standard p5 stuff
    canvas = null;
    texture = null;
    p5 = null;
    width = 500;
    height = 500;
    mousePosition = { x: 0, y: 0 }
    onSetup = null;

    //sketch variables
    particles = [];
    numParticles;
    particleDensity = 6000;
    lineProximityDistance = 100;
    recommendedMaxWidth = 1600;
    recommendedMaxHeight = 900;

    constructor(width, height) {
        if(width) this.width = width;
        if(height) this.height = height;
    }

    setup = (p5) =>  {
        const canvas = this.p5.createCanvas(this.width, this.height);
        canvas.canvas.style.display = 'none'
        canvas.parent("root");
        this.canvas = canvas.canvas;

        this.numParticles = this.width * this.height / this.particleDensity;
        for(let i = 0; i < this.numParticles; ++i)
        {
            // this.particles[i] = new Particle();
        }

        this.r = this.width / 2;
        if(this.onSetup) this.onSetup(this);
    }

    draw = (p5) => {
        if(this.texture)
            this.texture.needsUpdate = true;


        this.p5.blendMode(this.p5.BLEND);
        this.p5.background(51);	// same color as the top bar on openprocessing.org
        this.p5.smooth();
        this.p5.noStroke();
        this.p5.fill(0);

        for(let i = 0; i < this.numParticles; ++i) {
            // this.particles[i].update();
            // this.particles[i].display();
        }

        this.p5.blendMode(this.p5.ADD);
        this.p5.strokeWeight(3);
        for(let i = 0; i < this.numParticles; ++i) {
            // for(let j = i + 1; j < this.numParticles; ++j) {
            //     let distX = this.p5.abs(this.particles[i].pos.x - this.particles[j].pos.x);
            //     let distY = this.p5.abs(this.particles[i].pos.y - this.particles[j].pos.y);
            //     if(distX < this.lineProximityDistance && distY < this.lineProximityDistance) {
            //         let c = this.p5.color(this.p5.red(this.particles[i].color) + this.p5.red(this.particles[j].color),
            //             this.p5.green(this.particles[i].color) + this.p5.green(this.particles[j].color),
            //             this.p5.blue(this.particles[i].color) + this.p5.blue(this.particles[j].color),
            //             255 * this.p5.min(1, (this.lineProximityDistance - this.p5.max(distX, distY)) / (this.lineProximityDistance * 0.3)));
            //         this.p5.stroke(c);
            //         this.p5.line(this.particles[i].pos.x, this.particles[i].pos.y, this.particles[j].pos.x, this.particles[j].pos.y);
            //     }
            // }
        }

    }

    sketch = (p5) => {
        this.p5 = p5;
        p5.init = this.init;
        p5.draw = this.draw;
        p5.setup = this.setup;
    }

    onClick () {
        this.p5.background(0);
    }

    updateMousePosition = (x, y) => {
        this.mousePosition = { x, y }
    }
}

