import { makeAutoObservable } from "mobx";
import { createTheme } from "@mui/material/styles";
import { IsMobile } from "../libs/BrowserLib";
import { User } from "@auth0/auth0-react";

const defaultTheme = createTheme({
        "palette": {
            "primary": {
                "main": "#121331"
            },
            "secondary": {
                "main": "#cb04ff"
            }
        }
    });

export interface UserAttributes {
    username: string,
    avatar: string
    [key: string]: string
}

class App {

    theme = defaultTheme;
    isMobile = false;

    private accessTokenFunc: Function | null = null;

    userAttributes: UserAttributes = {
        username: "",
        avatar: ""
    }

    constructor() {
        makeAutoObservable(this);
        this.isMobile = IsMobile();
    }

    setAppTheme(theme: any){
        this.theme = createTheme(theme);
    }

    set getAccessTokenFunction(func: Function){
        this.accessTokenFunc = func;
    }

    getAccessToken(){
        if(!this.accessTokenFunc) throw new Error("Access token function has not been set.")
        return this.accessTokenFunc();
    }
}

export default new App()