// Possibly old code

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid, IconButton,
    MenuItem, Stack,
    TextField, Tooltip, Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng";
import { observer } from "mobx-react";
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import MicOffIcon from "@mui/icons-material/MicOff";
import MicIcon from "@mui/icons-material/Mic";
import VideocamOff from "@mui/icons-material/VideocamOff";
import Videocam from "@mui/icons-material/Videocam";

const DialogMultiplayerJoin = observer((props) => {
    const { open, multiplayer, chat, onJoin, onJoinSolo  } = props;

    const [channel, setChannel] = useState(props.channelId);

    const [videoDevices, setVideoDevices] = useState([]);
    const [audioDevices, setAudioDevices] = useState([]);

    const [selectedVideo, setSelectedVideo] = useState("");
    const [selectedAudio, setSelectedAudio] = useState("");

    const [joining, setJoining] = useState(false);

    const handleChangeId = (event) => {
        const id = event.target.value;
        setChannel(id);
    };

    const handleJoin = async () => {
       try
       {
           setJoining(true);
           await multiplayer.join(channel, selectedVideo, selectedAudio);
           await chat.join(`${channel}-chat`);
           onJoin();
       }catch (e)
       {

       }

       setJoining(false);
    };

    const handleJoinSolo = () => {
        onJoinSolo();
    }

    useEffect(() => {
        AgoraRTC.getDevices().then((devices) => {
            const audioDevices = devices.filter(function (device) {
                return device.kind === "audioinput";
            });

            setAudioDevices(audioDevices);
            setSelectedAudio(audioDevices[0].deviceId);

            const videoDevices = devices.filter(function (device) {
                return device.kind === "videoinput";
            });

            setVideoDevices(videoDevices);
            setSelectedVideo(videoDevices[0].deviceId);
        });
    }, []);

    // console.log(selectedVideo);
    // console.log(selectedAudio);
    // console.log(channel);

    const handleToggleVideo = () => {
        if (multiplayer.video_muted) {
            multiplayer.unmuteVideo();
        } else {
            multiplayer.muteVideo();
        }
    };

    const handleToggleAudio = () => {
        if (multiplayer.audio_muted) {
            multiplayer.unmuteAudio();
        } else {
            multiplayer.muteAudio();
        }
    };

    return (
        <Dialog open={open} fullWidth {...props}>
            <DialogTitle>Please select camera and microphone from the lists:</DialogTitle>
            <DialogContent>
                <Box py={1.0}></Box>
                    <Typography>
                        you can click the camera / microphone icons to switch off video or audio
                    </Typography>
                <Box py={1.0}></Box>


                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Stack direction='row' spacing={2}>
                            <Tooltip title="Select your camera">
                                <TextField
                                    label="Camera"
                                    fullWidth
                                    select
                                    variant="outlined"
                                    value={selectedVideo}
                                    onChange={(event) =>
                                        setSelectedVideo(event.target.value)
                                    }
                                >
                                    {videoDevices.map((device) => (
                                        <MenuItem
                                            key={device.deviceId}
                                            value={device.deviceId}
                                        >
                                            {device.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Tooltip>

                            <Box>
                                <Tooltip title='Toggle video'>
                                    <IconButton onClick={handleToggleVideo} size="large">
                                        {multiplayer.video_muted ? <VideocamOff /> : <Videocam />}
                                    </IconButton>
                                </Tooltip>
                            </Box>

                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction='row' spacing={2}>
                            <Tooltip title="Select your microphone">
                                <TextField
                                    label="Microphone"
                                    fullWidth
                                    select
                                    variant="outlined"
                                    value={selectedAudio}
                                    onChange={(event) =>
                                        setSelectedAudio(event.target.value)
                                    }
                                >
                                    {audioDevices.map((device) => (
                                        <MenuItem
                                            key={device.deviceId}
                                            value={device.deviceId}
                                        >
                                            {device.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Tooltip>

                            <Box>
                                <Tooltip title='Toggle audio'>
                                    <IconButton onClick={handleToggleAudio} size="large">
                                        {multiplayer.audio_muted ? <MicOffIcon /> : <MicIcon />}
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Stack>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <TextField
                            label="Enter your meeting code here"
                            fullWidth
                            variant="outlined"
                            value={channel}
                            onChange={handleChangeId}
                        />
                    </Grid>*/}
                    <Grid item xs={12}>
                        <Stack direction='row' spacing={2}>
                            <Button
                                onClick={handleJoin}
                                disabled={
                                    selectedAudio === "" ||
                                    selectedVideo === "" ||
                                    !channel ||
                                    joining
                                }
                                variant="outlined"
                                color="primary"
                                fullWidth
                            >
                                Join and explore with others
                            </Button>
                            <Button
                                onClick={handleJoinSolo}
                                disabled={
                                    joining
                                }
                                variant="outlined"
                                color="primary"
                                fullWidth
                            >
                                Join and explore solo
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
});

export default DialogMultiplayerJoin;
