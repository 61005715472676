import P5 from "p5";
import * as THREE from 'three';

export default class P5Sketch {

    canvas = null;
    texture = null;
    p5 = null;
    width = 500;
    height = 500;
    mousePosition = { x: 0, y: 0 }
    onSetup = null;

    constructor(width, height) {
        if(width) this.width = width;
        if(height) this.height = height;
    }

    setup = (p5) => {
        const canvas = this.p5.createCanvas(this.width, this.height);
        canvas.canvas.style.display = 'none'
        canvas.parent("root");
        this.canvas = canvas.canvas;
        if(this.onSetup) this.onSetup(this);
    }

    init = (p5) => {}

    draw (p5) {
        if(this.texture)
            this.texture.needsUpdate = true;
    }

    sketch = (p5) => {
        this.p5 = p5;
        p5.init = this.init;
        p5.draw = this.draw;
        p5.setup = this.setup;
    }

    onClick () {

    }

    updateMousePosition = (x, y) => {
        this.mousePosition = { x, y }
    }
}