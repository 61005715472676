export default class Clickable {
    static clickables = new Map();

    constructor(object, onClick, meta, recursive) {
        this.object = object;
        this.onClick = onClick;
        this.meta = meta;
        this.recursive = recursive;
        Clickable.clickables.set(object.uuid, this);
        if(this.recursive){
            object.traverse((node) => {
                Clickable.clickables.set(node.uuid, this);
            })
        }

    }

    handleClicked(intersection) {
        this.onClick(this, intersection);
    }

    dispose() {
        delete Clickable.clickables.delete(this.object.uuid);
        if(this.recursive){
            this.object.traverse((node) => {
                delete Clickable.clickables.delete(node.uuid);
            })
        }
    }
}
