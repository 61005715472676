import { Box, Button, Dialog, DialogContent, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import AppBar from "./UIAppBar";
import queryString from "query-string";
import config from "../config";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import DialogWelcome from "./DialogWelcome";
import { ImageMap } from '@qiuz/react-image-map';

const mapArea = [
  {
    "id": "ZOOM",
    "width": "20.0%",
    "height": "6.0%",
    "left": "41.0%",
    "top": "45.0%",
    "href": "101"
  },
    {
        "id": "METAVERSE",
        "width": "25.0%",
        "height": "4.0%",
        "left": "73.5%",
        "top": "44.0%",
        "href": "102"
    },
]

function UIWelcomeScreen(props) {

  const onMapClick = async (area) =>
  {
    if (area.id)
    {
      switch (area.id)
      {
        case "ZOOM":
        {
            window.open('https://mastercard.zoom.us/j/86763499917?pwd=d0FWbURkZ1F3dDF5WjRwMHllUk9udz09', '_blank')
          break;
        }
          case 'METAVERSE': {
              props.onInteracted();
              break;

          }
      }
    }
  }

  return (

      <>
        <div className="click-region-outer">
          <div className="click-region-inner">
              <ImageMap
                  className="click-region"
                  src="/assets/images/mastercardSplash/mastercardSplash_BG.jpg"
                  map={mapArea}
                  // commented out for holding page
                  onMapClick={onMapClick}
              />
              <img
                  className="click-region"
                  src="/assets/images/mastercardSplash/mastercardSplash_FG.png"
                  width="100%"
                  alt=''
                  style={{
                    zIndex: 1000,
                    pointerEvents: "none"
                  }}
              />
          </div>
        </div>
        <AppBar/>
      </>
  );
}
export default withAuthenticationRequired(UIWelcomeScreen)