import P5 from "p5";
// import "p5/lib/addons/p5.dom";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Clickable from "./Clickable";
import Hoverable from "./Hoverable";

export default class P5Object {

    mousePosition = { x: 0, y: 0}
    clicked = false;

    constructor(uuid, scene, modelUrl, videoFaceName, sketch) {

        this.model = new THREE.Object3D();
        scene.add(this.model)

        const width = 600;
        const height = 150;

        sketch.onSetup = (sketch) => {

            this.texture = new THREE.CanvasTexture(sketch.canvas);
            sketch.texture = this.texture;
            this.material = new THREE.MeshBasicMaterial({ map: this.texture });

            const loader = new GLTFLoader();

            loader.load(
                // resource URL
                modelUrl,
                // called when the resource is loaded
                (gltf) => {
                    this.model.add(gltf.scene);
                    this.model.traverse((node) => {
                        // search the mesh's children for the face-geo
                        if (node.isMesh && node.name === videoFaceName) {
                            node.material = this.material;
                        }
                    });

                    // new Clickable(gltf.scene, () => this.resetP5())
                    new Clickable(gltf.scene, (clickable, intersection) => {
                        if(intersection.uv){
                            const uv = intersection.uv;
                            intersection.object.material.map.transformUv( uv );
                            sketch.mousePosition = { x: uv.x, y: uv.y }
                            sketch.onClick();
                        }
                    }, undefined, true)

                    new Hoverable(gltf.scene, (hoverable, intersection) => {
                        if(intersection.uv){
                            const uv = intersection.uv;
                            intersection.object.material.map.transformUv( uv );
                            sketch.mousePosition = { x: uv.x, y: uv.y }
                        }
                    })
                },
                // called while loading is progressing
                ( xhr ) => {
                    console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
                },
                // called when loading has errors
                ( error ) => {
                    console.log( 'An error happened' );
                }
            );
        }

        new P5(sketch.sketch);
    }

    setPosition = (x, y, z) => {
        this.model.position.set(x, y, z);
    };

    setRotation = (x, y, z) => {
        this.model.rotateY(y * THREE.MathUtils.DEG2RAD);
    };
}