///////////////////////////////////////////////
//
//Neural graph letters
//
//https://openprocessing.org/sketch/1572543
//
///////////////////////////////////////////////
import P5Sketch from "./P5Sketch";
import P5 from "p5";

export default class ExampleSketch10
{
    //standard p5 stuff
    canvas = null;
    texture = null;
    p5 = null;
    width = 500;
    height = 500;
    mousePosition = { x: 0, y: 0 }
    onSetup = null;

    //sketch variables
    fc = 0;
    num = 3000;
    // ArrayList ballCollection;
    save = false;
    scal=0.0;
    thet=0.0;
    // PGraphics letter;
    // PFont font;
    l = "Geist";

    constructor(width, height) {
        if(width) this.width = width;
        if(height) this.height = height;
    }

    setup = (p5) =>  {
        const canvas = this.p5.createCanvas(this.width, this.height);
        canvas.canvas.style.display = 'none'
        canvas.parent("root");
        this.canvas = canvas.canvas;

        // this.p5.PGraphics.letter = this.p5.createGraphics(this.width, this.height);
        let fontMark = this.p5.loadFont("assets/fonts/Mark/MarkForMC-Bold.ttf");

        this.r = this.width / 2;
        if(this.onSetup) this.onSetup(this);
    }

    draw = (p5) => {
        if(this.texture)
            this.texture.needsUpdate = true;

    }

    sketch = (p5) => {
        this.p5 = p5;
        p5.init = this.init;
        p5.draw = this.draw;
        p5.setup = this.setup;
    }

    onClick () {
        this.p5.background(0);
    }

    updateMousePosition = (x, y) => {
        this.mousePosition = { x, y }
        // this.p5.background(this.mousePosition.x * 25,0,0);
        // this.p5.stroke(this.mousePosition.x * 25,0,0);
        // this.p5.background(x/10);
        // this.str_wei = x / 100;
    }
}

