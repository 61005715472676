import * as THREE from "three";
import Hoverable from "./Hoverable";
import MultiplayerSimple  from "./MultiplayerSimple.ts";
import Component from "./Component";
import App from "../core/App";
import AgoraRTC from "agora-rtc-sdk-ng";
import BelongService from "../core/BelongService";
import {BelongData} from "./BelongWelcome";
import {MapTranspartent, MapWithBg, WorldMap} from "../utilities/WorldMap";

export default class BelongInteractiveMap extends Component
{
    raycaster = new THREE.Raycaster();
    width = 3;
    height = 3;
    heightmap = null;
    inRange = true;
    activeDistance = 15;

    belongService = new BelongService();
    data = [];
    content = [];
    painter = setInterval(() => null, 50);
    optionSelected = "";

    constructor({
                    uuid,
                    scene,
                    images,
                    camera,
                    renderer,
                    width,
                    height,
                    activeDistance
                })
    {
        super();

        this.uuid = uuid;
        this.activeDistance = activeDistance;

        const root = document.getElementById("root");
        const canvas = document.createElement("canvas");
        canvas.style.display = "none";
        this.canvas = canvas;
        root.appendChild(canvas);

        canvas.id = uuid;
        this.ctx = canvas.getContext("2d");
        this.ctx.canvas.width = width * 1000;
        this.ctx.canvas.height = height * 1000;
        this.ctx.fillStyle = "#030303";
        this.ctx.fillRect(0, 0, canvas.width, canvas.height);

        this.ctx.font = "150px Mastercard";

        this.scene = scene;
        this.images = images;
        this.camera = camera;
        this.renderer = renderer;

        this.width = width || 3;
        this.height = height || 3;

        this.loader = new THREE.TextureLoader();

        this.geometry = new THREE.PlaneBufferGeometry(this.width * .96, this.height);
        const texture = new THREE.CanvasTexture(this.ctx.canvas);
        this.texture = texture;
        this.material = new THREE.MeshBasicMaterial({ map: texture });

        this.setupBillboard();

        this.board = new THREE.Mesh(this.geometry, this.material);
        scene.add(this.board);

        new Hoverable(this.board, (e) =>
        {
            document.body.style.cursor = "pointer";
        });

        this.renderer.domElement.addEventListener("pointerdown", (e) =>
        {
            const point = this.getMousePoint(e);
            console.log(point);

            if(point) {
                const normX = this.getNormalisedX(point.x);
                const normY = this.getNormalisedY(point.y);

                if(normX !== -1 && normY !== -1 && normY < 11 && normX < 5) {
                    this.option = BelongData.discoveryOptions[normX * 11 + normY];

                    if(this.option) this.selectOption(normX, normY, this.option);
                }
            }
        });
    }

    getMouseX = (event) =>
    {
        return (event.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
    };

    getMouseY = (event) =>
    {
        return -(event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1;
    };

    setupBillboard = () =>
    {
        let options = BelongData.discoveryOptions;
        this.ctx.textAlign = "left";
        this.drawMap();

        for(let i = 0; i < 11; i++) {
            for(let j = 0; j < 5; j++) {
                let currentText = options[j * 11 + i];

                if (currentText === this.option) {
                    this.ctx.fillStyle = this.mastercardGradient(currentText, "100px MastercardBold", this.ctx.canvas.width * 0.05 + 0.2 * j * this.ctx.canvas.width)
                    this.ctx.font = "100px MastercardBold";
                    this.ctx.fillText(currentText, this.ctx.canvas.width * 0.05 + 0.2 * j * this.ctx.canvas.width, this.ctx.canvas.height * 0.7 + i * 160);
                } else {
                    this.ctx.fillStyle = "#fff";
                    this.ctx.font = "100px MastercardLight";
                    this.ctx.fillText(currentText, this.ctx.canvas.width * 0.05 + 0.2 * j * this.ctx.canvas.width, this.ctx.canvas.height * 0.7 + i * 160);
                }
            }
        }

        if(this.option) {
            this.belongService.getCountriesByReflectionAsync(this.option)
                .then(countries => {
                    for(const c of countries) {
                        const countryMap = BelongData.countriesMap[c.location];

                        this.drawIndicator(countryMap.x, countryMap.y, c.count);
                    }
                })
        }

        this.texture.needsUpdate = true;
    };

    drawMap = () =>
    {
        const ctx = this.ctx;
        const texture = this.texture;
        const img = new window.Image();

        img.addEventListener("load", () =>
        {
            ctx.drawImage(img,
                0,
                0,
                ctx.canvas.width,
                ctx.canvas.height * 0.6);
            texture.needsUpdate = true;
        });

        img.setAttribute("src", MapWithBg);
    };

    drawIndicator = (x, y, count) => {
        const ctx = this.ctx;
        const normalisedX = ((x + 5.34) / 10.68) * ctx.canvas.width;
        const normalisedY = (1 - ((y + 3.24) / 6.48)) * ctx.canvas.height;

        ctx.fillStyle = "#000";
        ctx.strokeStyle = "#F00";
        ctx.lineWidth = 30;

        ctx.beginPath();
        ctx.arc(normalisedX, normalisedY, 70, 0, 2 * Math.PI); // TODO: Bigger blobs, circles -> increase this proportionally to font
        ctx.stroke();
        ctx.fill();
        ctx.closePath();

        ctx.fillStyle = "#FFF";
        ctx.font = "80px MastercardBold"; // TODO: Bigger blobs, circles -> increase this
        ctx.textAlign = "center";
        ctx.fillText(count, normalisedX, normalisedY + 28); // TODO: Bigger blobs, circles -> increase this proportionally to font
        this.texture.needsUpdate = true;
    }

    mastercardGradient = (text, font, x) => {
        this.ctx.font = font;
        const grd = this.ctx.createLinearGradient(x, 0, x + this.ctx.measureText(text).width, 0);
        grd.addColorStop(0, "#ff0409");
        grd.addColorStop(0.2, "#f93305");
        grd.addColorStop(0.5, "#fc5c04");
        grd.addColorStop(0.8, "#fd8a13");
        grd.addColorStop(1, "#fec104");

        return grd;
    }

    selectOption = () => {
        this.removeContent();
        this.setupBillboard();
    }

    removeContent = () =>
    {
        const canvas = this.ctx.canvas;

        this.ctx.clearRect(0, 0, canvas.width, canvas.height);
        this.ctx.fillStyle = "#030303";
        this.ctx.fillRect(0, 0, canvas.width, canvas.height);

    };

    getMousePoint = (e) =>
    {
        let x = this.getMouseX(e);
        let y = this.getMouseY(e);

        this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera);
        const intersects = this.raycaster.intersectObject(this.board);

        if (intersects.length === 0)
        {
            return;
        }

        const intersection = intersects[0];

        if (intersection && intersection.uv && intersection.distance < this.activeDistance)
        {
            return {
                x: intersects[0].uv.x * this.width - this.width / 2,
                y: intersects[0].uv.y * this.height - this.height / 2,
                uvx: intersects[0].uv.x,
                uvy: intersects[0].uv.y
            };
        }
    };

    setPosition = (x, y, z) =>
    {
        this.board.position.set(x, y, z);
    };

    setRotation = (x, y, z) =>
    {
        this.board.rotateY(y * THREE.MathUtils.DEG2RAD);
    };

    rotateY(angle)
    {
        this.board.rotateY(angle);
    }

    getNormalisedX = (value) =>
    {
        if(value > -4.8 && value < -3) {
            return 0;
        } else if(value > -3 && value < -0.8) {
            return 1;
        } else if(value > -0.8 && value < 1.2) {
            return 2;
        } else if(value > 1.2 && value < 3.2) {
            return 3;
        } else if(value > 3.2 && value < 5.1) {
            return 4;
        }

        return -1;
    }

    getNormalisedY = (value) =>
    {
        let count = 0;

        for(let i = -1.18; i >= -2.95; i -= 0.155) {
            if(value <= i && value > i - 0.22) {
                console.log(count)
                return count;
            } else {
                count++;
            }
        }

        return -1;
    }

    setupHeightMap(heightmap)
    {
        if (heightmap)
        {
            this.heightmap = heightmap;
            return true;
        }

        return false;
    }
}
