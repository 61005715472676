///////////////////////////////////////////////
//
//BLOB - not working because class and new
//
//https://openprocessing.org/sketch/161721
//
///////////////////////////////////////////////
import P5Sketch from "./P5Sketch";
import P5 from "p5";

// class Bubble {
//
//     constructor(p5, mass)-
//     {
//         this.p5=p5;
//         this.p5.PVector.loc;
//         this.p5.PVector.acc;
//         this.p5.PVector.vel;
//
//         this.mass = 1.0;
//         this.size = 0.0;
//     }
//
//     Bubble(this.p5.PVector loc_, float size_) {
//     this.loc = loc_;
//     this.vel = new PVector(this.p5random(-3, 3), this.p5random(-3, 3));
//
//     size = size_;
//
//
// }


export default class ExampleSketch7
{
    //standard p5 stuff
    canvas = null;
    texture = null;
    p5 = null;
    width = 500;
    height = 500;
    mousePosition = { x: 0, y: 0 }
    onSetup = null;

    //sketch variables
    bubbles = []
    lines = false;
    clickOnce;

    //Bubble class variable
    loc = this.p5.PVector;
    acc = this.p5.PVector;
    vel = this.p5.PVector;
    loc_ = this.p5.PVector;

    mass = 1.0;
    size = 0.0;
    mySize_ = 0.0;

    constructor(width, height) {
        if(width) this.width = width;
        if(height) this.height = height;
    }

    setup = (p5) =>  {
        const canvas = this.p5.createCanvas(this.width, this.height);
        canvas.canvas.style.display = 'none'
        canvas.parent("root");
        this.canvas = canvas.canvas;

        //sketch setup
        this.p5.frameRate(32);
        this.p5.noStroke();
        for (this.i = 0; this.i <= 100; this.i++) {
            //todo this is difficult
            // bubbles.add(new Bubble(new PVector(random(width), random(height)), random(5, 75)));
            this.bubbles.add(new this.Bubble(50, 50, this.p5.random(5, 75)));
        }

        this.r = this.width / 2;
        if(this.onSetup) this.onSetup(this);
    }

    draw = (p5) => {
        if(this.texture)
            this.texture.needsUpdate = true;

        //sketch draw
        this.p5.background(255);
        for (this.i = 0; this.i < this.bubbles.size(); this.i++) {
            // todo need to declare bubble as b
            //Bubble b = (Bubble) bubbles.get(i);
            // if (lines) {
            //     for (int q = 0; q < bubbles.size(); q++) {
            //         Bubble b2 = (Bubble) bubbles.get(q);
            //         float d = PVector.dist(b.loc, b2.loc);
            //         if (d < 100) {
            //             stroke(20, 200, 255);
            //             line(b.loc.x, b.loc.y, b2.loc.x, b2.loc.y);
            //         }
            //     }
            // }
            // b.draw();
            // b.moveBubble();
            // //b.applyForce(gravity);
            // b.boundaries();
        }
        this.p5.fill(0);
        // text((int)frameRate, 5, 15);

    }

    sketch = (p5) => {
        this.p5 = p5;
        p5.init = this.init;
        p5.draw = this.draw;
        p5.setup = this.setup;
    }

    onClick () {
        if (this.lines && this.clickOnce == false) {
            this.lines = false;
            this.clickOnce = true;
        }
        else if (!this.lines && this.clickOnce == false) {
            this.lines = true;
            this.clickOnce = true;
        }
    }

    updateMousePosition = (x, y) => {
        this.mousePosition = { x, y }
        // this.p5.background(this.mousePosition.x * 25,0,0);
        // this.p5.stroke(this.mousePosition.x * 25,0,0);
        // this.p5.background(x/10);
        // this.str_wei = x / 100;
    }
//todo ??? how to declare a class
//     class Bubble {
//    eg:???
//     class Bubble extends ?????{
    Bubble () {
    //todo ??? declare as PVector - seems hard to declare here
    //
    // PVector loc;
    // PVector acc;
    // PVector vel;
    // PVector loc;
    // PVector acc;
    // PVector vel;
    //
    // float mass = 1;
    //  float size;
    //todo so... we will try declaring them up at top of sketch

    this.loc = this.loc_;
    this.vel = new this.p5.PVector(this.p5.random(-3, 3), this.p5.random(-3, 3));

    this.size = this.size_;
}

//todo why is draw called again???
    draw() {
    this.p5.noStroke();
    this.p5.fill(0, 50);
    this.p5.ellipse(this.loc.x, this.loc.y, this.p5.size+20, this.p5.size+20);
    this.p5.fill(0);
    this.p5.ellipse(this.loc.x, this.loc.y, this.p5.size, this.p5.size);
    }

    moveBubble() {
        this.loc.this.p5.add(this.vel);
    }

    boundaries() {
    if (this.loc.y < 0)
        this.vel.y *= -1;
    if (this.loc.y > this.height)
        this.vel.y *= -1;
    if (this.loc.x > this.width)
        this.vel.x *= -1;
    if (this.loc.x < 0)
        this.vel.x *= -1;
    }


}

