import Scene from "../core/Scene";
import App from "../core/App";
import axios from "axios";
import config from "../config";
import * as THREE from 'three';
import Clickable from "./Clickable";
import Hoverable from "./Hoverable";

export interface SpeedDatingClientParams {
    scene: Scene
    buttonPosition: THREE.Vector3
    buttonScale: THREE.Vector3,
    returnButtonPosition: THREE.Vector3
    returnButtonScale: THREE.Vector3
    teleportPositionA: THREE.Vector3
    teleportRotationA: THREE.Euler
    teleportPositionB: THREE.Vector3
    teleportRotationB: THREE.Euler,
    returnTeleportPosition: THREE.Vector3
    returnTeleportRotation: THREE.Euler
}

export default class SpeedDatingClient {

    timeout: number = -1;
    prevChannelId: string = "";

    constructor(public readonly params: SpeedDatingClientParams) {
        // window.addEventListener("keydown", async (event) => {
        //     console.log(event)
        //     if(event.code === "KeyC"){
        //         await this.join()
        //     }
        // })

        const sphere = new THREE.SphereGeometry(0.2);
        const sphereReturn = new THREE.SphereGeometry(0.2);
        const buttonMesh = new THREE.Mesh(sphere, new THREE.MeshBasicMaterial({ color: "red" }))

        const clickable = new Clickable(buttonMesh, () => this.join());
        buttonMesh.position.copy(params.buttonPosition);
        buttonMesh.scale.copy(params.buttonScale);
        params.scene.scene.add(buttonMesh)

        const returnButtonMesh = new THREE.Mesh(sphereReturn, new THREE.MeshBasicMaterial({ color: "green" }))
        const clickable2 = new Clickable(returnButtonMesh, () => this.leave());
        returnButtonMesh.position.copy(params.returnButtonPosition);
        returnButtonMesh.scale.copy(params.returnButtonScale);
        params.scene.scene.add(returnButtonMesh)


        const showHelp = () => {
            // @ts-ignore
            document.getElementById("help-text").style.display = "block";
            // @ts-ignore
            document.getElementById("help-text").innerHTML = "Click this button to join the Seat at the Table Experience";
        };

        const showHelpReturn = () => {
            // @ts-ignore
            document.getElementById("help-text").style.display = "block";
            // @ts-ignore
            document.getElementById("help-text").innerHTML = "Click this button to return to the Expo";
        };

        const hideHelp = () => {
            // @ts-ignore
            (document.getElementById("help-text").style.display = "none");
        }

        const handleHover = () => {
            // this.buttonMesh.scale.setScalar(0.66);
            document.body.style.cursor = "pointer";
            showHelp();
        }

        const handleHoverReturn = () => {
            // this.buttonMesh.scale.setScalar(0.66);
            document.body.style.cursor = "pointer";
            showHelpReturn();
        }

        const handleUnhover = () => {
            // this.buttonMesh.scale.setScalar(0.6);
            hideHelp();
        }

        new Hoverable(
            buttonMesh,
            handleHover,
            handleUnhover
        );

        new Hoverable(
            returnButtonMesh,
            handleHoverReturn,
            handleUnhover
        );
    }


    async join() {
        if (!config.api.speed_dating_url)
            throw new Error("Missing url config for speed-dating feature.")

        this.prevChannelId = this.params.scene.multiplayer.baseMeetingId;

        const token = await App.getAccessToken();

        const result = await axios({
            url: `${config.api.speed_dating_url}/join`,
            method: "POST",
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })

        const { channelId, userCount } = result.data;

        console.log("Leaving multiplayer session...")
        await this.params.scene.multiplayer.leave();

        this.params.scene.multiplayer.onUserJoin.on(() => {
            if (this.params.scene.multiplayer.getUserCount() === 1) {
                console.log("Starting timer 1...")
                this.startTimeout();
            }
        })

        console.log("Joining multiplayer session...")
        await this.params.scene.multiplayer.join(channelId);

        if (this.params.scene.multiplayer.getUserCount() === 1) {
            console.log("Starting timer 2...")
            this.startTimeout();
        }

        if (userCount === 1) {
            const rot = new THREE.Quaternion().setFromEuler(this.params.teleportRotationA)
            this.params.scene.controls.moveTo(this.params.teleportPositionA, rot, rot, rot, rot, true);

        } else {
            const rot = new THREE.Quaternion().setFromEuler(this.params.teleportRotationB)
            this.params.scene.controls.moveTo(this.params.teleportPositionB, rot, rot, rot, rot, true);
        }

        // this.params.scene.multiplayer.onLeave.on(this.leave);
        this.params.scene.multiplayer.onUserLeave.on(this.leave);
    }

    private leave = async() => {
        console.log("Leaving multiplayer session...")
        window.clearTimeout(this.timeout);
        await this.params.scene.multiplayer.leave();

        // this.params.scene.multiplayer.onLeave.off(this.leave);
        this.params.scene.multiplayer.onUserLeave.off(this.leave);

        const rot = new THREE.Quaternion().setFromEuler(this.params.returnTeleportRotation)
        this.params.scene.controls.moveTo(this.params.returnTeleportPosition, rot, rot, rot, rot, true);

        window.setTimeout(async () => {
            await this.params.scene.multiplayer.join(this.prevChannelId);
        },500)
    }

    private startTimeout = () => {
        this.timeout = window.setTimeout(() => {
            this.leave();
        }, 300000)
    }
}