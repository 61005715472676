///////////////////////////////////////////////
//
//HARRY'S ARRAY BLOB DEMO - this is a legacy sketch
//
//https://openprocessing.org/sketch/161721
//
///////////////////////////////////////////////


import P5Sketch from "./P5Sketch";
import P5 from "p5";

export default class ExampleSketch3 extends P5Sketch {

    bubbles = []
    lines = false;
    clickOnce;
    loc = [];
    loc_ = [];
    acc = [];
    mass= 0.0;
    size= 0.0;

    //how to declare a class??
    Bubble = () => {


        // Bubble(loc,loc_,size_)
        // {
        //     // loc = loc_;
        //     // vel = new PVector(random(-3, 3), random(-3, 3));
        //
        //     size = size_;
        // }
    }

    setup(p5)
    {
        //super.setup(p5);

        this.p5.frameRate(12);
        this.p5.noStroke();
        for (let i = 0; i <= 100; i++) {
        // this.p5.bubbles.add(new Bubble(new this.p5.PVector(this.p5.random(this.p5.width), this.p5.random(this.p5.height)), this.p5.random(5, 75)));
        }
    }

    draw = (p5) => {
        /////
        super.draw(p5);

        this.p5.background(255);

        // for (i = 0; i < bubbles.size(); i++) {
        //     Bubble b = (Bubble) bubbles.get(i);
        //     if (lines) {
        //         for (int q = 0; q < bubbles.size(); q++) {
        //             Bubble b2 = (Bubble) bubbles.get(q);
        //             float d = PVector.dist(b.loc, b2.loc);
        //             if (d < 100) {
        //                 stroke(20, 200, 255);
        //                 line(b.loc.x, b.loc.y, b2.loc.x, b2.loc.y);
        //             }
        //         }
        //     }
        //     b.draw();
        //     b.moveBubble();
        //     //b.applyForce(gravity);
        //     b.boundaries();
        // }
        // fill(0);
        // //text??
        // // text((int)frameRate, 5, 15);
    }

    mousePressed() {
        // if (this.p5.lines && clickOnce == false) {
        //     lines = false;
        //     clickOnce = true;
        // }
        // else if (!lines && clickOnce == false) {
        //     lines = true;
        //     clickOnce = true;
        // }
    }

    mouseReleased() {
        // clickOnce = false;
    }

    onClick = () => {
        //onclick is from example2
        super.onClick();
        this.starScale += 1;
    }





    myDraw() {
        this.p5.noStroke();
        this.p5.fill(0, 50);
        this.p5.ellipse(this.p5.loc.x, this.p5.loc.y, this.p5.size+20, this.p5.size+20);
        this.p5.fill(0);
        this.p5.ellipse(this.p5.loc.x, this.p5.loc.y, this.p5.size, this.p5.size);
    }

    moveBubble() {
        this.p5.loc.add(this.p5.vel);
    }

    boundaries() {
        if (this.p5.loc.y < 0)
            this.p5.vel.y *= -1;
        if (this.p5.loc.y > this.p5.height)
            this.p5.vel.y *= -1;
        if (this.p5.loc.x > this.p5.width)
            this.p5.vel.x *= -1;
        if (this.p5.loc.x < 0)
            this.p5.vel.x *= -1;
    }
}