///////////////////////////////////////////////
//
//SPIROGRAPH
//
//https://openprocessing.org/sketch/1431113
//
///////////////////////////////////////////////
import P5Sketch from "./P5Sketch";
import P5 from "p5";
export default class ExampleSketch4
{
//standard p5 stuff
    canvas = null;
    texture = null;
    p5 = null;
    width = 500;
    height = 500;
    mousePosition = { x: 0, y: 0 }
    onSetup = null;

//sketch variables
    seed = Math.random() * 1000;
    k
    l = 0.125;
    th = 0
    seg = 0.075;
    str_wei = 0.5;
    r;
    colors1 = "f8f9fbBF-f9f6e040-45454cBF-ef9643b3-f0654380"
        .split("-")
        .map((a) => "#" + a);
    colors2 = "f8f9fb-f9f6e0-45454c-ef9643"
        .split("-")
        .map((a) => "#" + a);
    colorbg = "f06543".split("-").map((a) => "#" + a);
    colorbg2 = "f0654301".split("-").map((a) => "#" + a);
    col_sel;

    constructor(width, height) {
        if(width) this.width = width;
        if(height) this.height = height;
    }

    setup = (p5) =>  {
        const canvas = this.p5.createCanvas(this.width, this.height);
        canvas.canvas.style.display = 'none'
        canvas.parent("root");
        this.canvas = canvas.canvas;

        this.k = this.p5.random(0.5, 1.125);
        this.p5.pixelDensity(5);
        // this.p5.background(this.colorbg);
        this.p5.background(50,25,25);
        this.r = this.width / 2;
        if(this.onSetup) this.onSetup(this);
    }

    draw = (p5) => {
        if(this.texture)
            this.texture.needsUpdate = true;

        this.p5.randomSeed(this.seed);
        let T = (1 - this.k) / this.k;
        this.p5.strokeWeight(this.str_wei);
        this.p5.stroke(this.p5.random(this.colors2));
        // this.p5.stroke(this.mousePosition.x * 25,0,0);
        this.p5.noFill();
        this.p5.push();
        this.p5.translate(this.width / 2, this.height / 2);
        this.p5.line(this.r * ((1 - this.k) * Math.cos(this.th) + this.l * this.k * Math.cos(T * this.th)), this.r * ((1 - this.k) * Math.sin(this.th) - this.l * this.k * Math.sin(T * this.th)),
            this.r * ((1 - this.k) * Math.cos(this.th + this.seg) + this.l * this.k * Math.cos(T * (this.th + this.seg))), this.r * ((1 - this.k) * Math.sin(this.th + this.seg) - this.l * this.k * Math.sin(T * (this.th + this.seg))));
        this.p5.pop();
        this.th += this.seg;
        this.r += 0.1;
        // background(colorbg2);

    }

    sketch = (p5) => {
        this.p5 = p5;
        p5.init = this.init;
        p5.draw = this.draw;
        p5.setup = this.setup;
    }

    onClick () {
        this.p5.background(0);
    }

    updateMousePosition = (x, y) => {
        this.mousePosition = { x, y }
        // this.p5.background(this.mousePosition.x * 25,0,0);
        // this.p5.stroke(this.mousePosition.x * 25,0,0);
        // this.p5.background(x/10);
        // this.str_wei = x / 100;
    }
}

