///////////////////////////////////////////////
//
//THIS IS A BLANK SKETCH
//
//https://
//
///////////////////////////////////////////////
import P5Sketch from "./P5Sketch";
import P5 from "p5";
export default class ExampleSketch15
{
    //standard p5 stuff
    canvas = null;
    texture = null;
    p5 = null;
    width = 500;
    height = 500;
    mousePosition = { x: 0, y: 0 }
    onSetup = null;

    //sketch variables
    string = "Hello Open Processing!"; //words to be displayed intially
    size = 120; //font size
    fontFile = "assets/fonts/Mark/MarkForMC-Bold.ttf";
    showText = true; //whether or not to have an overlay of the original text (in the background color)
    textAlpha = 1; //the alpha of the text if displayed (low value will make it slowly fade in)
    backgroundColor = 8; //kinda self-explanatory
    strokeAlpha = 60; //the alpha of the lines (lower numbers are more transparent)
    strokeColor = 254; //the line color
    fontSampleFactor = 0.3; //How many points there are: the higher the number, the closer together they are (more detail)
    noiseZoom = 0.006; //how zoomed in the perlin noise is
    noiseOctaves = 4; //The number of octaves for the noise
    noiseFalloff = 0.5; //The falloff for the noise layers
    zOffsetChange = 0; //How much the noise field changes in the z direction each frame
    individualZOffset = 0; //how far away the points/lines are from each other in the z noise axies (the bigger the number, the more chaotic)
    lineSpeed = 14; //the maximum amount each point can move each frame
    newPointsCount = 9; //the number of new points added when the mouse is dragged
    seed;
    font;
    points = [];
    startingPoints;

    constructor(width, height) {
        if(width) this.width = width;
        if(height) this.height = height;
    }

    setup = (p5) =>  {
        const canvas = this.p5.createCanvas(this.width, this.height);
        canvas.canvas.style.display = 'none'
        canvas.parent("root");
        this.canvas = canvas.canvas;

        this.font = this.p5.loadFont("assets/fonts/Mark/MarkForMC-Bold.ttf");
        this.p5.textFont(this.font);
        this.p5.fill(this.backgroundColor, this.textAlpha);
        this.p5.stroke(this.strokeColor, this.strokeAlpha);
        //       ??
        this.p5.noiseDetail(this.noiseOctaves, this.noiseFalloff);
        this.seed = this.p5.floor(this.p5.random(1000000));
        this.start();

        this.r = this.width / 2;
        if(this.onSetup) this.onSetup(this);
    }

    start(){
    this.p5.background(this.backgroundColor);
    this.p5.textSize(this.size);

        this.p5.randomSeed(this.seed);
        this.p5.noiseSeed(this.seed);
        this.p5.frameCount = 0;
        // this.startingPoints = this.p5.font.textToPoints(this.string, this.width / 2 - this.p5.textWidth(this.string) / 2,
        //     this.height / 2, this.size, {"sampleFactor": this.fontSampleFactor});

    this.points = [];
    // for (let p = 0; p < this.startingPoints.length; p++) {
        // this.points[p] = this.startingPoints[p];
        // this.points[p].zOffset = this.p5.random();
    // }
}

    draw = (p5) => {
        if(this.texture)
            this.texture.needsUpdate = true;

        if(this.showText){
            this.p5.noStroke();
            this.p5.text(this.string, this.width / 2 - this.p5.textWidth(this.string) / 2, this.height / 2);
            this.p5.stroke(this.strokeColor, this.strokeAlpha);
        }
        for (let pt = 0; pt < this.points.length; pt++) {
            let p = this.points[pt];
            let noiseX = p.x * this.noiseZoom;
            let noiseY = p.y * this.noiseZoom;
            let noiseZ = this.frameCount * this.zOffsetChange + p.this.zOffset*this.individualZOffset;
            let newPX = p.x + this.p5.map(this.p5.noise(this.noiseX, this.noiseY, this.noiseZ), 0, 1, -this.lineSpeed, this.lineSpeed);
            let newPY = p.y + this.p5.map(this.p5.noise(this.noiseX, this.noiseY, this.noiseZ + 214), 0, 1, -this.lineSpeed, this.lineSpeed);
            this.p5.line(p.x, p.y, newPX, newPY);
            p.x = newPX;
            p.y = newPY;
        }


    }

    sketch = (p5) => {
        this.p5 = p5;
        p5.init = this.init;
        p5.draw = this.draw;
        p5.setup = this.setup;
    }

    onClick () {
        this.p5.background(0);
    }

    updateMousePosition = (x, y) => {
        this.mousePosition = { x, y }
        // this.p5.background(this.mousePosition.x * 25,0,0);
        // this.p5.stroke(this.mousePosition.x * 25,0,0);
        // this.p5.background(x/10);
        // this.str_wei = x / 100;
    }
}

