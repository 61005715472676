import config from "../config"
import App from "./App";

export interface BelongResponse {
    location: string,
    picture: BelongPicture,
    discoveryChoices: string[],
    acceptanceStatement: string
}

export interface BelongPicture {
    rectangular: string,
    circular: string
}

export default class BelongService {
    baseUrl = config.api.belong_exp_url;

    constructor() {
    }

    async submitAnswersAsync(answer: BelongResponse) {
        const token = await App.getAccessToken();
        const request = {
            location: answer.location,
            discoveryChoices: answer.discoveryChoices,
            acceptanceStatement: answer.acceptanceStatement,
            picture: {
                rectangular: answer.picture.rectangular
            }
        }

        await fetch(`${this.baseUrl}/answer`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(request)
        });

        return true;
    }

    async getPicturesAsync(count: any) {
        const token = await App.getAccessToken();

        const response = await fetch(`${this.baseUrl}/picture?count=${count}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return await response.json();
    }

    async getReflectionsAsync() {
        const token = await App.getAccessToken();

        const response = await fetch(`${this.baseUrl}/answer`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const obj = await response.json();

        return obj.map((x: any) => x.acceptanceStatement).sort(() => .5 - Math.random());
    }

    async getCountriesByReflectionAsync(reflection: string) {
        const token = await App.getAccessToken();

        const response = await fetch(`${this.baseUrl}/answer?reflection=${reflection}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return await response.json();
    }
}
