import * as THREE from "three";
import Clickable from "./Clickable";
// import UnlitImageMaterial from "./UnlitMaterial";
import Hoverable from "./Hoverable";

export default class ModalTriggerPDF {
    constructor(scene, onClick, pdfObject, src, width) {
        this.scene = scene;

        // const geometry = new THREE.PlaneBufferGeometry(width, width);
        // const material = new UnlitImageMaterial({src});
        // const boardPDF = new THREE.Mesh(geometry, material);
        // material.obj = boardPDF;
        // this.boardPDF = boardPDF;
        // this.scene.add(this.boardPDF);
        // this.boardPDF.rotateY(90 * THREE.MathUtils.DEG2RAD);
        // this.boardPDF.scale.set(.33, .33, .33);

        this.object = pdfObject;

        const handleClick = (clickable) => {
            onClick(true);
        };
        // new Clickable(this.boardPDF, handleClick)

        const mySpriteMaterial = new THREE.SpriteMaterial({
            map: new THREE.TextureLoader().load("assets/icons/ZoneVS-pdf.png"),
            transparent: true,
        });

        const objectSpritePDF = new THREE.Sprite(mySpriteMaterial);
        this.objectSpritePDF = objectSpritePDF;
        scene.add(objectSpritePDF);
        objectSpritePDF.scale.set(0.4, 0.4, 0.4);
        new Clickable(this.objectSpritePDF, handleClick);

        const showHelp = () => {
            document.getElementById("help-text").style.display = "block";
            document.getElementById("help-text").innerHTML = "Click here to download a PDF document";
        };
        const hideHelp = () => (document.getElementById("help-text").style.display = "none");

        // new Hoverable(this.objectSpritePDF, () => {
        //     document.body.style.cursor = 'pointer';
        // });

        new Hoverable(
            this.objectSpritePDF,
            () => {
                document.body.style.cursor = "pointer";
                this.objectSpritePDF.scale.set(0.45, 0.45, 0.45);
                showHelp();
            },
            () => {
                this.objectSpritePDF.scale.set(0.4, 0.4, 0.4);
                hideHelp();
            }
        );
    }

    setPosition = (x, y, z) => {
        this.objectSpritePDF.position.set(x, y, z);
    };

    setPositionFromArray = (arr) => {
        this.setPosition(arr[0], arr[1], arr[2]);
    };

    setRotation = (x, y, z) => {
        // this.boardPDF.rotateY(y * THREE.MathUtils.DEG2RAD);
    };

    setScale = (x, y, z) => {
        // this.setScale(arr[0], arr[1], arr[2]);
    };
}
