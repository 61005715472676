import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

export default function LoginPage() {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const login = async () => {
      await loginWithRedirect();
    };

    login();
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  return <p>Logging in...</p>;
}
