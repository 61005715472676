///////////////////////////////////////////////
//
//MERCEDES COG STAR
//
//HTTP://
//
///////////////////////////////////////////////
import P5Sketch from "./P5Sketch";
export default class ExampleSketch extends P5Sketch {

    starScale = 1;

    setup(p5)
    {
        super.setup(p5);
    }

    draw = (p5) => {
        console.log(p5)
        super.draw(p5);
        this.p5.background(this.mousePosition.x * 25,0,0);

        this.p5.push();
        this.p5.translate(this.width * 0.2, this.height * 0.5);
        this.p5.rotate(this.p5.frameCount / 200.0);
        this.star(this.p5,0, 0, 5, 70, 3);
        this.p5.pop();

        this.p5.push();
        this.p5.translate(this.width * 0.5,this.height * 0.5);
        this.p5.rotate(this.p5.frameCount / 50.0);
        this.star(this.p5,0, 0, 80 * this.starScale, 100, 40);
        this.p5.pop();

        this.p5.push();
        this.p5.translate(this.width * 0.8, this.height * 0.5);
        this.p5.rotate(this.p5.frameCount / -100.0);
        this.star(this.p5,0, 0, 30, 70, 5);
        this.p5.pop()

        if(this.starScale > 1){
            this.starScale -= 0.02;
        }
    }

    star(p5, x, y, radius1, radius2, npoints) {
        let angle =p5.TWO_PI / npoints;
        let halfAngle = angle / 2.0;
        p5.beginShape();
        for (let a = 0; a <p5.TWO_PI; a += angle) {
            let sx = x +p5.cos(a) * radius2;
            let sy = y +p5.sin(a) * radius2;
           p5.vertex(sx, sy);
            sx = x +p5.cos(a + halfAngle) * radius1;
            sy = y +p5.sin(a + halfAngle) * radius1;
           p5.vertex(sx, sy);
        }
       p5.endShape(p5.CLOSE);
    }

    onClick = () => {
        super.onClick();
        this.starScale += 1;
    }
}