import {
    Autocomplete,
    Button, createFilterOptions,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
} from "@mui/material";

export default function DialogAutocompleteField(props) {
    const { open, onChange, onClose, options, label, actionText, suggestionLimit } = props;

    const defaultFilterOptions = createFilterOptions();
    const filterOptions = (options, state) => {
        return defaultFilterOptions(options, state).slice(0, suggestionLimit);
    };

    return (
        <Dialog open={open} maxWidth="xs" fullWidth>
            <DialogContent style={{minHeight: "25vh"}}>
                <Autocomplete
                    disablePortal
                    autoFocus
                    fullWidth
                    autoComplete={false}
                    filterOptions={filterOptions}
                    options={options}
                    onChange={(event) => onChange(event.target.innerText)}
                    renderInput={(params) => <TextField {...params} label={label} />}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>{actionText}</Button>
                <Button onClick={() => onClose(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}
