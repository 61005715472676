import {Box, Dialog as MuiDialog, IconButton, useMediaQuery, useTheme} from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import * as React from "react";
import {Fullscreen, FullscreenExit} from "@mui/icons-material";
import {useState} from "react";

export default function Dialog(props){
    const theme = useTheme();

    const fullscreenRecommended = useMediaQuery(theme.breakpoints.down('sm'))
    const [forceFullscreen, setForceFullscreen] = useState(fullscreenRecommended);

    const { open, children, disableClose, disableForceFullscreen, analytics_id, ...rest } = props;

    const handleToggleFullScreen = () => {
        setForceFullscreen(!forceFullscreen)
    }

    return (
        <MuiDialog open={open} fullScreen={fullscreenRecommended || forceFullscreen} {...rest}>
            {children}
            {!disableClose && (
                <Box p={1} position="absolute" top={0} right={0}>
                    <IconButton size="small" onClick={props.onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
            )}
            {!disableForceFullscreen && !fullscreenRecommended && (
                <Box p={1} position="absolute" top={0} right={40}>
                    <IconButton size="small" onClick={handleToggleFullScreen}>
                        {forceFullscreen ? <FullscreenExit/> : <Fullscreen /> }

                    </IconButton>
                </Box>
            )}
        </MuiDialog>
    )
}