import React, { useEffect, useRef, useState } from "react";
import Scene from "./core/Scene";
import exhibitionSpace from "./rooms/exhibitionSpace.json";
import mastercard from "./rooms/mastercard.json";
import queryString from "query-string";
import axios from "axios";
import DialogMultiplayerJoin from "./components/DialogMultiplayerJoin";
import { observer } from "mobx-react";
import Drawer from "./components/UIInAppMenuDrawer";
import UIToolbar2 from "./components/UIToolbar2";
import DialogTextField from "./components/DialogTextField";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Box, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
//import { IsBrowserCompatible } from "./libs/BrowserLib";
import { IsBrowserCompatible, IsMobile } from "./libs/BrowserLib";
import DialogIncompatibleBrowser from "./components/DialogIncompatibleBrowser";
import App from "./core/App";
import DialogIncompatibleMobil from "./components/DialogIncompatibleMobil";
import config from '../src/config'
import TransitionLobbyOverlay from "./components/TransitionLobbyOverlay";
import FadeBlack from "./components/TransitionFadeBlack";
import { useAuth0 } from "@auth0/auth0-react";
import DialogNotAuthorised from "./components/DialogNotAuthorised";
import ChatWidget from "./components/ChatWidget";
import DialogAutocompleteField from "./components/DialogAutocompleteField";
import {BelongData} from "./three-components/BelongWelcome";

//NOTE entry code can be changed in: DialogMultiplayerJoin.jsx: const channel
//NOTE browser/os guards are in libs/browserlib.js
//NOTE - keep GLB under 10mb iff poss for mobile
//NOTE - HTML email template in the ZoneVS folder - email to harry to update


const DynamicScene = observer((props) => {

    const mount = useRef(null);
    const [engine, setEngine] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [fadeBlack, setFadeBlack] = useState(true);

    const handleFullscreen = useFullScreenHandle();
    const [joinOpen, setJoinOpen] = useState(false);
    const [channelId, setChannelId] = useState("");

    const [textFieldValue, setTextFieldValue] = useState("");

  const { isAuthenticated, user, loginWithRedirect, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if(user){
            App.userAttributes.avatar = user?.picture;
            App.userAttributes.username = user?.email;
        }
    }, [user]);

    useEffect(() => {
        App.getAccessTokenFunction = getAccessTokenSilently;
    }, [getAccessTokenSilently]);

  const onChangeTextField = (value) => {
    setTextFieldValue(value);
  };

    const onConfirmTextField = () => {
        setTextFieldValue("");
        engine?.activeWhiteboard?.setCurrentTextValue(textFieldValue);
    };

    const onCancelTextField = () => {
        setTextFieldValue("");
        engine?.activeWhiteboard?.deleteCurrentTextField();
        engine?.activeWhiteboard?.enableDrawingMode();
    };

    const onPhotoboothConfirmTextField = () => {
        setTextFieldValue("");
        engine?.activePhotobooth?.setCurrentTextValue(textFieldValue);
    };

    const onPhotoboothCancelTextField = () => {
        setTextFieldValue("");
    };

  // const [debugDialog, setDebugDialog] = useState(true);
  const [incompatibleOpen, setIncompatibleOpen] = useState(false);
  const [incompatibleMobileOpen, setIncompatibleMobileOpen] = useState(false);
  const [linkError, setLinkError] = useState();
    const [wrongEmailDialogOpen, setWrongEmailDialogOpen] = useState(false);

  useEffect(() => {
    const compatible = IsBrowserCompatible();
    setIncompatibleOpen(!compatible);
  }, [setIncompatibleOpen]);

  //CHRIS ADDED THIS IN APRIL 2022 to try to get mobile detect working
  useEffect(() => {
    const mobile = IsMobile();
    setIncompatibleMobileOpen(mobile);
  }, [setIncompatibleMobileOpen]);

  // useEffect(() => {
  //     if(config.app.defaultChannelId){
  //         setChannelId(config.app.defaultChannelId);
  //         setJoinOpen(true);
  //     }
  // }, [config.app.defaultChannelId])

  useEffect(() => {
    if (!engine) return;

    const parsed = queryString.parse(window.location.search);

    const extractMeeting = () => {
      const existingInvitation = localStorage.getItem("invitation");
      if (parsed.invitation) {
        return parsed.invitation;
      } else if (existingInvitation) {
        localStorage.removeItem("invitation");
        return JSON.parse(existingInvitation);
      }
    };

    const invitation = extractMeeting();

    const validateLink = async (invitation) => {
      try {
        const response = await axios({
          url: `${config.api.public_url}/meetings/join`,
          method: "POST",
          data: {
            token: invitation,
          },
        });

        const { channel, room, email } = response.data;

        if (email) {
          if (!isAuthenticated) {
            localStorage.setItem("invitation", JSON.stringify(invitation));
            return await loginWithRedirect();
          }

          if (email !== user.email) {
            console.error("User email does not match invitation email!");
            return setWrongEmailDialogOpen(true);
          }
        }

        setChannelId(channel);
        setJoinOpen(true);

        if (parsed.sceneOverride) {
          engine.loadRoomById(parsed.sceneOverride);
        } else {
          engine.loadRoomById(room);
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e.response.status === 403) {
            setLinkError(e.response.data.message);
          }
        }
      }
    };

    if (invitation && App.isMobile) {
      setIncompatibleMobileOpen(true);
    }

    if (invitation && !App.isMobile) {
      validateLink(invitation);
    } else {
      const override = parsed.sceneOverride;
      if (override) {
        engine.loadRoomById(override);
      } else {
        // startup scene
        if (App.isMobile) {
          engine.loadRoomById(config.app.defaultMobileRoom);
        } else if (!App.isMobile) {
          engine.loadRoomById(config.app.defaultDesktopRoom);
        }
      }
    }

    const defaultChannel = engine?.roomData?.multiplayer_default_channel;
    if(defaultChannel){
        setChannelId(defaultChannel);
        setJoinOpen(true);
    }
  }, [engine, isAuthenticated, user]);

    useEffect(() => {
        const engine = new Scene(mount.current);
        setEngine(engine);
    }, []);

    useEffect(() => {
        setFadeBlack(false);
    }, [])

    useEffect(() => {
        if(engine){
            if(!engine.loading){
                window.setTimeout(() => {
                    setFadeBlack(true);
                    window.setTimeout(() => {
                        setPlaying(true);
                        setFadeBlack(false)
                    }, 1000)
                }, 1000)
                //other value is in TransitionFadeBlack.jsx
            }
        }
    }, [engine, engine?.loading])

  return (
    <>
      <Dialog open={Boolean(linkError)}>
        <DialogTitle>{linkError}</DialogTitle>
        <DialogContent>
          <Button onClick={props.onLinkExpired}>Take me home</Button>
        </DialogContent>
      </Dialog>
      {/*//comment this out to remove debug*/}
      {/*<BrowserDebugDialog open={debugDialog} onClose={() => setDebugDialog(false)} />*/}
      <DialogNotAuthorised open={wrongEmailDialogOpen} onClose={() => setWrongEmailDialogOpen(false)} />
      <DialogIncompatibleMobil open={incompatibleMobileOpen} onClose={() => setIncompatibleMobileOpen(false)} />
      <DialogIncompatibleBrowser open={incompatibleOpen} onClose={() => setIncompatibleOpen(false)} />
      {engine && !engine.loading && (
        <>
          {channelId && (
            <DialogMultiplayerJoin
              open={joinOpen}
              channelId={channelId}
              multiplayer={engine.multiplayer}
              chat={engine.chat}
              onJoin={(event, reason) => {
                  setJoinOpen(false)
              }}
              onJoinSolo={() => {
                  setJoinOpen(false);
              }}
            />
          )}
          <Drawer engine={engine} />
          <UIToolbar2 engine={engine} onFullScreen={() => handleFullscreen.enter()} />
        </>
      )}

            {engine && engine.activeWhiteboardTextOpen && (
                <DialogTextField
                    open={engine.activeWhiteboardTextOpen}
                    value={textFieldValue}
                    onChange={onChangeTextField}
                    onClose={onConfirmTextField}
                    onCancel={onCancelTextField}
                />
            )}

            {engine && engine.photoboothCountryOpen && (
                <DialogAutocompleteField
                    open={engine.photoboothCountryOpen}
                    actionText={"Select country"}
                    options={BelongData.countries}
                    label={"Country"}
                    suggestionLimit={6}
                    onChange={onChangeTextField}
                    onClose={onPhotoboothConfirmTextField}
                    onCancel={onPhotoboothCancelTextField}
                />
            )}

            {engine && engine.photoboothTextOpen && (
                <DialogTextField
                    open={engine.photoboothTextOpen}
                    value={textFieldValue}
                    onChange={onChangeTextField}
                    onClose={onPhotoboothConfirmTextField}
                    onCancel={onPhotoboothCancelTextField}
                />
            )}

            <FadeBlack in={fadeBlack} />

            {!playing && (
                <Box
                    width="100%"
                    height="100%"
                    position="absolute"
                    bgcolor="#000000"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <TransitionLobbyOverlay/>
                </Box>
            )}

            {engine?.chat && <ChatWidget client={engine.chat} />}

            <FullScreen handle={handleFullscreen}>
                <div ref={mount} />
            </FullScreen>
        </>
    );
});

export default DynamicScene;
