import * as THREE from 'three';

export default class PinchDetector {
    constructor(el, onPinch) {
        el.onpointerdown = this.pointerdown_handler;
        el.onpointermove = this.pointermove_handler;

        // Use same handler for pointer{up,cancel,out,leave} events since
        // the semantics for these events - in this app - are the same.
        el.onpointerup = this.pointerup_handler;
        el.onpointercancel = this.pointerup_handler;
        el.onpointerout = this.pointerup_handler;
        el.onpointerleave = this.pointerup_handler;
        this.onPinch = onPinch;
    }

    // Global vars to cache event state
    evCache = [];
    prevDiff = -1;

    pointerdown_handler = (ev) => {
        // The pointerdown event signals the start of a touch interaction.
        // This event is cached to support 2-finger gestures
        this.evCache.push(ev);
    }

    pointermove_handler = (ev) => {
        // This function implements a 2-pointer horizontal pinch/zoom gesture.
        //
        // If the distance between the two pointers has increased (zoom in),
        // the target element's background is changed to "pink" and if the
        // distance is decreasing (zoom out), the color is changed to "lightblue".
        //
        // This function sets the target element's border to "dashed" to visually
        // indicate the pointer's target received a move event.

        // Find this event in the cache and update its record with this event
        for (let i = 0; i < this.evCache.length; i++) {
            if (ev.pointerId === (this.evCache)[i].pointerId) {
                this.evCache[i] = ev;
                break;
            }
        }

        // If two pointers are down, check for pinch gestures
        if (this.evCache.length === 2) {

            const touch1 = new THREE.Vector2((this.evCache)[0].clientX, (this.evCache)[0].clientY);
            const touch2 = new THREE.Vector2((this.evCache)[1].clientX, (this.evCache)[1].clientY);

            // Calculate the distance between the two pointers
            const curDiff = Math.abs(touch1.distanceTo(touch2));

            const increased = curDiff > this.prevDiff;
            const decreased = curDiff < this.prevDiff;

            if (this.prevDiff > 0) {
                if (increased) {
                    this.onPinch({
                        deltaTime: ev.deltaTime,
                        distance: curDiff,
                        increased: true,
                        delta: (this.prevDiff || curDiff) - curDiff
                    })

                }
                if (decreased) {
                    this.onPinch({
                        deltaTime: ev.deltaTime,
                        distance: curDiff,
                        decreased: true,
                        delta: (this.prevDiff || curDiff) - curDiff
                    })
                }
            }

            // Cache the distance for the next move event
            this.prevDiff = curDiff;
        }
    }

    pointerup_handler = (ev) => {
        // Remove this pointer from the cache and reset the target's
        // background and border
        this.remove_event(ev);

        // If the number of pointers down is less than two then reset diff tracker
        if (this.evCache.length < 2) this.prevDiff = -1;
    }

    remove_event = (ev) =>  {
        // Remove this event from the target's cache
        for (let i = 0; i < this.evCache.length; i++) {
            if ((this.evCache)[i].pointerId === ev.pointerId) {
                this.evCache.splice(i, 1);
                break;
            }
        }
    }
}