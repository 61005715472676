///////////////////////////////////////////////
//
//CONSTELLATION  - not working because class and new
//
//https://openprocessing.org/sketch/1146244
//
///////////////////////////////////////////////
import P5Sketch from "./P5Sketch";
import P5 from "p5";

class Star {

    constructor(p5)
    {
        this.p5=p5;
        this.a = 0.0; // "5*TAU" => render will be more homogeneous
        this.r = 0.0;
        this.loc = 0.0;
        // this.speed =p5.createVector();
        // this.speed = p5.Vector.random2D();
        // this.speed.p5.random2D();
        this.bam = [];
        this.m=0;

        // this.a = this.p5.random(5 * this.p5.TAU); // "5*TAU" => render will be more homogeneous
        // this.r = this.random(this.width * .4, this.width * .25); // first position will looks like a donut
        // this.loc = this.createVector(this.width / 2 + this.sin(this.a) * this.r, this.height / 2 + this.cos(this.a) * this.r);
        // this.speed = this.createVector();
        // this.speed = createVector();
        // this.speed = p5.Vector.random2D();
        // //this.speed.random2D();
        // this.bam = createVector();
        // this.m;
    }


    // speed =this.p5.createVector();



    update ()  {
        this.bam = this.p5.Vector.random2D(); // movement of star will be a bit erractic
        //not used
        //this.bam.random2D();
        this.bam.mult(0.45);
        this.speed.add(this.bam);
        // speed is done according distance between loc and the mouse :
        // we do mousex differently so check
        // this.m = this.p5.constrain(this.p5.map(this.p5.dist(this.loc.x, this.loc.y, mouseX, mouseY), 0, width, 8, .05), .05, 8); // constrain => avoid returning "not a number"
        this.m = this.p5.constrain(this.p5.map(this.p5.dist(this.loc.x, this.loc.y, 1, 1), 0, 1, 8, .05), .05, 8); // constrain => avoid returning "not a number"
        this.speed.this.p5.normalize().this.p5.mult(this.m);

    //     // No colision detection, instead loc is out of bound
    //     // it reappears on the opposite side :
        if (this.p5.dist(this.loc.x, this.loc.y, this.width / 2, this.height / 2) > (this.width / 2) * 0.98) {
            if (this.loc.x < this.width / 2) {
                this.loc.x = this.width - this.loc.x - 4; // "-4" => avoid blinking stuff
            } else if (this.loc.x > this.width / 2) {
                this.loc.x = this.width - this.loc.x + 4; // "+4"  => avoid blinking stuff
            }
            if (this.loc.y < this.height / 2) {
                this.loc.y = this.width - this.loc.y - 4;
            } else if (this.loc.x > this.height / 2) {
                this.loc.y = this.width - this.loc.y + 4;
            }
        }
        this.loc = this.loc.add(this.speed);
    } // End of update()
} // End of class



export default class ExampleSketch7
{
    //standard p5 stuff
    canvas = null;
    texture = null;
    p5 = null;
    width = 500;
    height = 500;
    mousePosition = { x: 0, y: 0 }
    onSetup = null;

    //sketch variables
    constellation = [];
    n;
    d;
    m;

    constructor(width, height)
    {
        if (width) this.width = width;
        if (height) this.height = height;
    }

    setup = (p5) =>
    {
        const canvas = this.p5.createCanvas(this.width, this.height);
        canvas.canvas.style.display = 'none'
        canvas.parent("root");
        this.canvas = canvas.canvas;


        this.p5.pixelDensity(1); // Set 1 because it's too slow on firefox
        //not used
        //pixelDensity(displayDensity());
        this.n = 200;
        for (this.i = 0; this.i <= this.n; this.i++)
        {
            //todo we cannot do "new"
            // this.constellation.push(new star());
            // this.constellation.this.p5.push(this.star());
            // this.constellation(new this.star());

            //fromo working example
            // this.str_arr.push(new Type(this.p5, strs[i%strs.length], x, y, z));

            this.constellation.push(new Star());
            //
            // this.constellation(new this.star());
        }
        this.p5.strokeWeight(.75);
        this.p5.stroke('#00FFaF');

        //todo ??? needed?
        this.r = this.width / 2;

        if (this.onSetup) this.onSetup(this);
    }

    draw = (p5) =>
    {
        if (this.texture)
            this.texture.needsUpdate = true;

        this.p5.background(50);

        // for (var i = 0; i < constellation.length; i++) {
        //     constellation[i].update();
        //     for (var j = 0; j < constellation.length; j++) {
        //         if (i > j) { // "if (i > j)" => to check one time distance between two stars
        //             d = constellation[i].loc.dist(constellation[j].loc); // Distance between two stars
        //             if (d <= width / 8) { // if d is less than width/10 px, we draw a line between the two stars
        //                 line(constellation[i].loc.x, constellation[i].loc.y, constellation[j].loc.x, constellation[j].loc.y)
        //             }
        //         }
        //     }
        // }

    }

    sketch = (p5) =>
    {
        this.p5 = p5;
        p5.init = this.init;
        p5.draw = this.draw;
        p5.setup = this.setup;
    }

    onClick()
    {
        this.p5.background(0);
    }

    updateMousePosition = (x, y) =>
    {
        this.mousePosition = { x, y }
        // this.p5.background(this.mousePosition.x * 25,0,0);
        // this.p5.stroke(this.mousePosition.x * 25,0,0);
        // this.p5.background(x/10);
        // this.str_wei = x / 100;
    }

}



