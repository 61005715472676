import Dialog from "./Dialog";
import React from "react";
export function VideoViewerDialog(props) {

    const { title, url, open, onClose, ...rest } = props;

    const handleClose = () => {
        onClose();
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} {...rest}>
                <iframe
                    src={props.url}
                    style={{ marginTop: "45px", border: "none", minHeight: "75vh" }}
                    scrolling="no"
                    name="myiFrame"
                    allowFullScreen
                />
            </Dialog>
        </>
    );
}


