import "./App.css";
import { Auth0Provider } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import config from "./config";
import * as Sentry from "@sentry/react";
//todo - import this package properly
import { Integrations } from "@sentry/tracing";
import CssBaseline from "@mui/material/CssBaseline";
import {
    ThemeProvider,
    StyledEngineProvider,
} from "@mui/material/styles";
import DynamicScene from "./DynamicScene";
import DialogCookieConsent from "./components/DialogCookieConsent";
import { observer } from "mobx-react";
import AppStore from './core/App'
import UIWelcomeScreen from "./components/UIWelcomeScreen";
import FadeBlack from "./components/TransitionFadeBlack";
import queryString from "query-string";
import { Router, Link } from "@reach/router";
import AboutPage from "./pages/about";
import { Box } from "@mui/material";
import OAPrivacyPage from "./pages/oaprivacy";
import OAAboutPage from "./pages/oaabout";
import OAContactPage from "./pages/oacontact";
import OAInstructionsPage from "./pages/oainstructions";
import OAZonevsPage from "./pages/oazonevs";
import LoginPage from "./pages/login";
import { Logout } from "@mui/icons-material";
import LogoutPage from "./pages/logout";
import AppBar from "./components/UIAppBar";
import FaqPage from "./pages/faq";
import HomePage from "./pages/home";

const App = observer(() => {

    useEffect(() => {
        if (config.sentry.enabled) {
            /*Sentry.init({
                // todo - turn this on
                // Where to Find Your DSN:  view Settings -> Projects -> Client Keys (DSN) in sentry.io
                dsn: "https://05601bf81bfc45caaa4df6e0ecf2e467@o468348.ingest.sentry.io/6044439",
                integrations: [new Integrations.BrowserTracing()],

                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 1.0,
            });*/
        }
    }, []);

    const [playing, setPlaying] = useState(false);
    const [fadeToBlack, setFadeToBlack] = useState(false);

    const handleInteract = () => {
        setFadeToBlack(true);

        window.setTimeout(() => {
            setPlaying(true);
        }, 1000)
    }

    const handleLinkExpired = () => {
        setPlaying(false);
        setFadeToBlack(false)
        window.history.pushState({}, document.title, "/");
    }

    useEffect(() => {
        const parsed = queryString.parse(window.location.search);
        if(parsed.sceneOverride){
            // handleInteract();
        }
        const redirect = localStorage.getItem("redirect_link");
        if (redirect) {
          localStorage.removeItem("redirect_link");
          return (window.location.href = redirect);
        }
    }, [])

    const onRedirectCallback = async appState => {
        console.log(appState)

        //window.location.href = appState?.returnTo || window.location.pathname
    };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Auth0Provider
        domain={config.auth0.domain}
        clientId={config.auth0.clientId}
        redirectUri={window.location.href}
        audience="zonevs"
        scope="openid profile user_metadata"
        onRedirectCallback={onRedirectCallback}
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={AppStore.theme}>
            <CssBaseline />

                {/* Image Preload */}
                <img src="./assets/logos/blurb/zonevs-logo.svg" style={{display: "none"}} alt={""} />
                <img src="./assets/logos/blurb/zonevs-logo-dark.svg" style={{display: "none"}} alt={""} />
                <img src="./assets/logos/blurb/transparent.png" style={{display: "none"}} alt={""} />
                <img src="./assets/logos/blurb/transparent_2.png" style={{display: "none"}} alt={""} />
                <img src="./assets/logos/blurb/zonevs-logo-welcome.svg" style={{display: "none"}} alt={""} />
                <img src="./assets/images/blurb/about.jpg" style={{display: "none"}} alt={""} />
                <img src="./assets/images/blurb/contact.jpg" style={{display: "none"}} alt={""} />
                <img src="./assets/images/blurb/pricing.jpg" style={{display: "none"}} alt={""} />

              <DialogCookieConsent />
              {/*<AppBar />*/}
            <Router>
              {playing && <DynamicScene path="/" onLinkExpired={handleLinkExpired} />}
              {!playing && <HomePage path="/" onClickEnterMetaverse={handleInteract} />}
              {!playing && <FadeBlack path="/" in={fadeToBlack} />}
              <AboutPage path="/about" />
              {/*<PrivacyPage path="/privacy" />*/}
              <LoginPage path="/login" />
              <LogoutPage path="/logout" />
              <OAAboutPage path="/oaabout" />
              <OAPrivacyPage path="/oaprivacy" />
              <OAContactPage path="/oacontact" />
                <FaqPage path='/faq'/>
              <OAInstructionsPage path="/oainstructions" />
              {/*<OAZonevsPage path="/oazonevs" />*/}
            </Router>
          </ThemeProvider>
        </StyledEngineProvider>
      </Auth0Provider>
    </div>
  );
});


export default App;
