import { FunctionComponent } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const questions: any[] = [
    {
        question: "What am i?",
        answer: "zonevs"
    },
    {
        question: "How do I zonevs?",
        answer: "zonevs"
    }
]

const FaqPage: FunctionComponent<any> = () => {
    return (
        <Container sx={{ p: 4 }}>
            <Typography variant='h3' paragraph>FAQ</Typography>
            {questions.map((question, index) => (
                <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{question.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {question.answer}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Container>
    )
}

export default FaqPage;