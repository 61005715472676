import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import * as THREE from 'three';
import { UserAttributes } from "../core/App";
import config from "../config";

export default class PlayerModel {

    model!: THREE.Group;


    constructor(public readonly uid: string, public readonly video: HTMLVideoElement, public readonly attributes?: UserAttributes) {

    }

    public async init(){
        const loader = new GLTFLoader();
        const gltf = await loader.loadAsync(config?.avatar?.modelUrl || "assets/models/client.glb");
        const model = gltf.scene;
        model.name = this.uid;
        model.scale.setScalar(0.7);
        model.userData.init = false;
        model.visible = false;

        const texture = new THREE.VideoTexture(this.video);
        texture.minFilter = THREE.LinearFilter;
        texture.magFilter = THREE.LinearFilter;
        texture.flipY = false;

        this.model = model;

        model.traverse((node: THREE.Mesh | any) => {
            // search the mesh's children for the face-geo
            if (node.isMesh && node.name === "face-geo") {
                node.material.map = texture;
            }
        });

        // Generate label

        const labelBaseScale = 0.00333;
        const canvas = this.makeLabelCanvas(labelBaseScale * 1000, 175, 32, this.attributes?.username?.split("@")[0] || this.uid);
        const labelTexture = new THREE.CanvasTexture(canvas);
        // because our canvas is likely not a power of 2
        // in both dimensions set the filtering appropriately.
        labelTexture.minFilter = THREE.LinearFilter;
        labelTexture.wrapS = THREE.ClampToEdgeWrapping;
        labelTexture.wrapT = THREE.ClampToEdgeWrapping;

        const labelMaterial = new THREE.SpriteMaterial({
            map: labelTexture,
            transparent: true,
            opacity: 0.8
        });

        // if units are meters then 0.01 here makes size
        // of the label into centimeters.
        const label = new THREE.Sprite(labelMaterial);
        model.add(label);
        label.position.y = 0.6;
        label.scale.x = canvas.width  * labelBaseScale;
        label.scale.y = canvas.height * labelBaseScale;
    }

    private makeLabelCanvas(scale: number, baseWidth: number, size: number, name: string) {
        const borderSize = 2;
        const ctx = document.createElement('canvas').getContext('2d')!;
        const font =  `${size}px bold Verdana, sans-serif`;
        ctx.font = font;
        // measure how long the name will be
        const textWidth = ctx.measureText(name).width;

        const doubleBorderSize = borderSize * 2;
        let width = baseWidth + doubleBorderSize;
        let height = size + doubleBorderSize;

        width *= scale
        height *= scale;

        ctx.canvas.width = width
        ctx.canvas.height = height

        // need to set font again after resizing canvas
        ctx.font = font;
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';

        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, width, height);

        // scale to fit but don't stretch
        const scaleFactor = Math.min(1, baseWidth / textWidth);
        ctx.translate(width / 2, height / 2);
        ctx.scale(scaleFactor * scale, scale);
        ctx.fillStyle = 'black';
        ctx.fillText(name, 0, 0);

        return ctx.canvas;
    }
}