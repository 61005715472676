import React, { Fragment, FunctionComponent, useEffect, useRef, useState } from "react";
import {
    Badge,
    Box,
    Button,
    IconButton, Link,
    ListItemButton,
    Paper,
    Popover,
    Stack,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Chat, Edit } from "@mui/icons-material";
import ArrowLeft from '@mui/icons-material/ArrowBack';
import ChatClient, { ChatThread } from "../core/ChatClient";
import { observer } from "mobx-react";
import { useIsVisible } from "../hooks/useIsVisible";

interface ChatWidgetProps {
    client: ChatClient
}

const ChatWidget : FunctionComponent<ChatWidgetProps> = observer((props) => {

    const { client } = props;

    const { user } = useAuth0();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [selectedThread, setSelectedThread] = useState<ChatThread>();

    const [view, setView] = useState("inbox");

    const threads = [...client.threads.values()];
    const onlineUsers = [...client.users.values()];

    const [messageValue, setMessageValue] = useState("");

    const messagesEndRef = useRef<HTMLDivElement>(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        client.onMessage.on(() => {
            scrollToBottom()
        })
    }, []);

    const handleStartConversation = (uid: string) => {
        const thread = client.createThread(uid);
        setMessageValue("")
        setSelectedThread(thread);
        setView("thread");
    }

    const handleClickThread = (uid: string) => {
        const thread = client.getThread(uid);
        thread.read = true;
        setMessageValue("")
        setSelectedThread(thread);
        setView("thread");
    }

    const handleSendMessage = async () => {
        if(selectedThread){
            await client.sendMessage(selectedThread?.uid, messageValue.trim());
            scrollToBottom();
            setMessageValue("")
        }
    }

    return (
        <>
            <Box sx={{ position: "absolute", top: "50%", right: 20 }} >
                <Tooltip title='Open chat'>
                    <IconButton onClick={handleClick} size='large' sx={{ color: "white" }}>
                        <Badge badgeContent={threads.filter((x) => !x.read).length} color="primary">
                            <Chat/>
                        </Badge>
                    </IconButton>
                </Tooltip>
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
            >
                <Paper
                    sx={{ p: 2, width: 350, minHeight: 500 }}
                >
                    {view === 'inbox' && (
                        <Stack spacing={2}>
                            <Box display='flex' flexDirection='row' alignItems='center'>
                                <List dense>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar src={user?.picture} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={<Typography variant='h6'>Inbox</Typography>}
                                        />
                                    </ListItem>
                                </List>
                                <Box flexGrow={1}/>
                                <Tooltip title='Start new chat'>
                                    <IconButton size='small' onClick={() => setView('new')}>
                                        <Edit fontSize='small'/>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Divider/>
                            <Box minHeight={500} display='flex'>
                                <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                    {threads.map((thread) => (
                                        <Fragment key={thread.uid}>
                                            <ListItemButton onClick={() => handleClickThread(thread.uid)}>
                                                <ListItemAvatar>
                                                    <Avatar src={thread.avatar} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={thread.name}
                                                    secondary={
                                                        thread?.latestMessage?.message ||  "-"
                                                    }
                                                />
                                            </ListItemButton>
                                            <Divider variant="inset" component="li" />
                                        </Fragment>
                                    ))}
                                    {threads.length === 0 && (
                                        <Box mt={6}>
                                            <Link
                                                component='button'
                                                align='center'
                                                color='textSecondary'
                                                variant='subtitle1'
                                                onClick={() => setView('new')}
                                            >
                                                Click here to start a conversation with someone
                                            </Link>
                                        </Box>
                                    )}
                                </List>
                            </Box>
                        </Stack>
                    )}
                    {view === 'thread' && selectedThread && (
                        <Stack spacing={1}>
                            <Box display='flex' flexDirection='row' alignItems='center'>
                                <IconButton size='small' onClick={() => setView('inbox')}>
                                    <ArrowLeft fontSize='small'/>
                                </IconButton>
                                <List dense>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar src={selectedThread.avatar} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={selectedThread.name}
                                            secondary={onlineUsers.find((x) => x.uid === selectedThread.uid) ? "Online" : "Offline"}
                                        />
                                    </ListItem>
                                </List>
                            </Box>
                            <Divider/>
                            <Box height={500} sx={{ overflowY: "auto" }}>
                                <Stack spacing={2}>
                                    {selectedThread.messages.map((message) => (
                                        <Box
                                            key={message.timestamp}
                                            display='flex'
                                            justifyContent={message.sender.uid === client.uid ? "flex-end" : "flex-start"}
                                        >
                                            <Box
                                                sx={{
                                                    borderRadius: 2,
                                                    backgroundColor: message.sender.uid === client.uid ? "rgb(200, 250, 205)" : "rgb(244, 246, 248)",
                                                    p: 1,
                                                    px: 2
                                                }}
                                            >
                                                <Typography variant='caption'>{message.message}</Typography>
                                            </Box>
                                        </Box>
                                    ))}
                                    <div ref={messagesEndRef} />
                                </Stack>
                            </Box>
                            <Stack direction='row' spacing={1}>
                                <TextField
                                    fullWidth
                                    placeholder='Write a message'
                                    size='small' onChange={(event) => setMessageValue(event.target.value)} value={messageValue} />
                                <Button
                                    size='small'
                                    disabled={messageValue.trim() === ""}
                                    color='primary'
                                    variant='contained'
                                    onClick={handleSendMessage}
                                >
                                    Send
                                </Button>
                            </Stack>
                        </Stack>
                    )}
                    {view === 'new' && (
                        <Stack spacing={2}>
                            <Box display='flex' flexDirection='row' alignItems='center'>
                                {/*<IconButton size='small' onClick={() => setView('inbox')}>*/}
                                {/*    <ArrowLeft fontSize='small'/>*/}
                                {/*</IconButton>*/}
                                {/*<Typography></Typography>*/}


                                <List dense>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <IconButton onClick={() => setView('inbox')}>
                                                <ArrowLeft />
                                            </IconButton>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={<Typography variant='h6'>Online users</Typography>}
                                        />
                                    </ListItem>
                                </List>
                            </Box>
                            <Divider/>
                            <List dense sx={{ width: '100%', height: 500, maxWidth: 360, bgcolor: 'background.paper' }}>
                                {onlineUsers.map((user) => (
                                    <Fragment key={user.uid}>
                                        <ListItemButton onClick={() => handleStartConversation(user.uid)}>
                                            <ListItemAvatar>
                                                <Avatar src={user.avatar} />
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={user.username}
                                                secondary={onlineUsers.includes(user) ? "Online" : "Offline"}
                                            />
                                        </ListItemButton>
                                    </Fragment>
                                ))}
                                {onlineUsers.length === 0 && (
                                    <Box mt={6}>
                                        <Typography
                                            align='center'
                                            color='textSecondary'
                                            variant='subtitle1'
                                        >
                                            Looks like you're the only one online at the moment :(
                                        </Typography>
                                    </Box>
                                )}
                            </List>
                        </Stack>
                    )}
                </Paper>
            </Popover>
        </>

    )
});

export default ChatWidget;