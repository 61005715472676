import { Box, Button, Grid, Stack } from "@mui/material";
import React, { useEffect, useState, FunctionComponent } from "react";
import queryString from "query-string";
import { useAuth0 } from "@auth0/auth0-react";
import UIAppBar from "../../components/UIAppBar";
import config from "../../config";

const redirectButtonLabelKey = 'REDIRECT_JOIN_BUTTON_LABEL'

interface DefaultHomePageProps {
    onInteracted: Function
}

const DefaultHomePage : FunctionComponent<DefaultHomePageProps> = (props) => {

    const [buttonLabel, setButtonLabel] = useState("GET STARTED");
    const { isAuthenticated, loginWithRedirect, user,  } = useAuth0();

    useEffect(() => {
        const override = localStorage.getItem(redirectButtonLabelKey);
        if(override){
            localStorage.removeItem(redirectButtonLabelKey);
            setButtonLabel(override);
        }
    })

    useEffect(() =>
    {
        const parsed = queryString.parse(window.location.search);
        if (parsed.invitation)
        {
            setButtonLabel("JOIN MEETING");
        }
    }, []);

    //
    // useEffect(() => {
    //   console.log(user)
    //   if(isAuthenticated && user && !user.email_verified){
    //     alert("Email not verified.")
    //   }
    // }, [isAuthenticated, user])

    const handleStart = () => {
        if(!isAuthenticated){
            localStorage.setItem(redirectButtonLabelKey, "JOIN MEETING")
            //localStorage.setItem("redirect_link", window.location.href)
            loginWithRedirect({
                appState: {
                    redirectTo: window.location.href
                }
            });
        }else {
            props.onInteracted();
        }
    }

    return (
        <>
            <Box
                width="100%"
                height="100%"
                top={0}
                position="absolute"
                bgcolor="#ffffff"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <div id="home-top-video">
                    <video
                        className="App-bgvideo" playsInline loop muted autoPlay poster={config.landingPage.backgroundVideoPoster} id="bgvid">
                        <source src={config.landingPage.backgroundVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className="viewport-header">
                    <Grid container>
                        <Grid container>
                            <Grid item xs={1}>
                                <img src="assets/images/transparent.png" alt={""} />
                            </Grid>
                            <Grid item xs={10}>
                                <img src="assets/logos/zonevs-logo-welcome.svg" alt={""} />
                            </Grid>
                            <Grid item xs={1}>
                                <img src="assets/images/transparent.png" alt={""} />
                            </Grid>
                        </Grid>
                        <Grid>
                            <img src="assets/images/transparent_2.png" alt={""} />
                        </Grid>
                        <Grid container justifyContent='center'>
                            <Grid item xs={12} className="custom-text-Barlow-Regular-small-upper">
                                <Button
                                    style={{ padding: "11px 0px" }}
                                    variant="contained" fullWidth color="primary"
                                    onClick={handleStart}>{buttonLabel}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Stack>
                </Stack>
            </Box>
            <UIAppBar/>
        </>

    );
}

export default DefaultHomePage;
