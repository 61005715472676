import * as THREE from "three";
import Clickable from "./Clickable";
// import UnlitImageMaterial from "./UnlitMaterial";
import Hoverable from "./Hoverable";

export default class ModalTriggerURL {
    constructor(scene, onClick, urlObject, src, width) {
        this.scene = scene;

        // const geometry = new THREE.PlaneBufferGeometry(width, width);
        // const material = new UnlitImageMaterial({src});
        // const boardURL = new THREE.Mesh(geometry, material);
        // material.obj = boardURL;
        // this.boardURL = boardURL;
        // this.scene.add(this.boardURL);
        // this.boardURL.rotateY(90 * THREE.MathUtils.DEG2RAD);
        // this.boardURL.scale.set(.33, .33, .33);

        this.object = urlObject;

        const handleClick = (clickable) => {
            onClick(true);
        };
        // new Clickable(this.boardURL, handleClick)

        const mySpriteMaterial = new THREE.SpriteMaterial({
            map: new THREE.TextureLoader().load("assets/icons/trans.png"),
            transparent: true,
        });

        const objectSpriteURL = new THREE.Sprite(mySpriteMaterial);
        this.objectSpriteURL = objectSpriteURL;
        scene.add(objectSpriteURL);
        objectSpriteURL.scale.set(2.0, 2.0, 2.0);
        new Clickable(this.objectSpriteURL, handleClick);

        const showHelp = () => {
            document.getElementById("help-text").style.display = "block";
            document.getElementById("help-text").innerHTML = "Click here to visit a related website";
        };
        const hideHelp = () => (document.getElementById("help-text").style.display = "none");

        // new Hoverable(this.objectSpriteURL, () => {
        //     document.body.style.cursor = 'pointer';
        // });

        new Hoverable(
            this.objectSpriteURL,
            () => {
                document.body.style.cursor = "pointer";
                this.objectSpriteURL.scale.set(2.1, 2.1, 2.1);
                showHelp();
            },
            () => {
                this.objectSpriteURL.scale.set(2.0, 2.0, 2.0);
                hideHelp();
            }
        );
    }

    setPosition = (x, y, z) => {
        this.objectSpriteURL.position.set(x, y, z);
    };

    setPositionFromArray = (arr) => {
        this.setPosition(arr[0], arr[1], arr[2]);
    };

    setRotation = (x, y, z) => {
        // this.boardURL.rotateY(y * THREE.MathUtils.DEG2RAD);
    };

    setScale = (x, y, z) => {
        // this.setScale(arr[0], arr[1], arr[2]);
    };
}
