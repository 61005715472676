import * as THREE from "three";
import Hoverable from "./Hoverable";
import Multiplayer from "./MultiplayerSimple";
import Component from "./Component";
import App from "../core/App";

export default class Whiteboard3 extends Component {
    raycaster = new THREE.Raycaster();
    isDrawing = false;
    width = 3;
    height = 3;
    lineWidth = 12;

    mode = "drawing";

    history = [];

    prevColor = new THREE.Color("black");
    color = new THREE.Color("black");

    lastDraw = 0;



    constructor({
        uuid,
        scene,
        images,
        camera,
        renderer,
        width,
        height,
        onDrawStart,
        onDrawStop,
        onEnterTextMode,
        onLeaveTextMode,
        drawDistance,
        lineWidth,
        fontSize
    }) {
        super();

        this.uuid = uuid;
        this.drawDistance = drawDistance || 8;
        this.lineWidth = lineWidth || 12.0;
        this.fontSize = fontSize || 50.0;

        const root = document.getElementById("root");
        const canvas = document.createElement("canvas");
        canvas.style.display = "none";
        this.canvas = canvas;
        root.appendChild(canvas);

        canvas.id = uuid;
        this.ctx = canvas.getContext("2d");
        this.ctx.canvas.width = width * 1000;
        this.ctx.canvas.height = height * 1000;
        this.ctx.fillStyle = "#FFF";
        this.ctx.fillRect(0, 0, canvas.width, canvas.height);
        this.ctx.lineCap = "round";
        this.ctx.strokeStyle = "#000";
        this.ctx.lineWidth = this.lineWidth;

        Multiplayer.instance.registerCustomEventHandler(this.uuid, this);

        this.scene = scene;
        this.images = images;
        this.camera = camera;
        this.renderer = renderer;
        this.onDrawStart = onDrawStart;
        this.onDrawStop = onDrawStop;

        this.onCreateText = onEnterTextMode;
        this.onCreateTextLeave = onLeaveTextMode;

        this.width = width || 3;
        this.height = height || 3;

        this.loader = new THREE.TextureLoader();

        this.geometry = new THREE.PlaneBufferGeometry(this.width*.96, this.height);
        const texture = new THREE.CanvasTexture(this.ctx.canvas);
        this.texture = texture;
        this.material = new THREE.MeshBasicMaterial({ map: texture });

        this.board = new THREE.Mesh(this.geometry, this.material);
        scene.add(this.board);

        this.renderer.domElement.addEventListener("pointermove", (e) => {
            const time = Date.now();
            if (this.isDrawing && this.lastDraw + 32 < time) {
                this.lastDraw = time;
                // console.log(time);

                const position = this.getMousePoint(e);
                if (!position) return;

                const { uvx, uvy } = position;

                const x = uvx * this.ctx.canvas.width;
                const y = this.ctx.canvas.height - uvy * this.ctx.canvas.height;

                if (!this.lastPos) {
                    this.lastPos = { x, y };
                }

                this.ctx.strokeStyle = `#${this.color.getHexString()}`;

                this.ctx.beginPath();
                this.ctx.moveTo(this.lastPos.x, this.lastPos.y);
                this.ctx.lineTo(x, y);
                this.ctx.lineWidth = this.lineWidth;
                this.ctx.stroke();
                this.ctx.closePath();

                Multiplayer.instance.sendCustomEvent(this.uuid, "remoteDraw", {
                    strokeStyle: this.ctx.strokeStyle,
                    lineWidth: this.lineWidth,
                    lastPos: this.lastPos,
                    pos: { x, y },
                });

                this.lastPos = { x, y };
            }
        });

        this.renderer.domElement.addEventListener("pointerdown", (e) => {
            if(App.isMobile){
                document.getElementById("help-text").style.display = "block";
                document.getElementById("help-text").innerHTML =
                    "Whiteboards are not available on mobile";

                if(this.mobileWarningMessageTimeout){
                    window.clearTimeout(this.mobileWarningMessageTimeout);
                }

                this.mobileWarningMessageTimeout = window.setTimeout(() => {
                    document.getElementById("help-text").style.display = "none";
                }, 3000)

                return;
            }

            const point = this.getMousePoint(e);
            if (point) {
                switch (this.mode) {
                    case "drawing": {
                        this.isDrawing = true;
                        this.onDrawStart();
                        break;
                    }
                    case "text": {
                        if (!point) return;
                        const { uvx, uvy } = point;

                        const x = uvx * this.ctx.canvas.width;
                        const y =
                            this.ctx.canvas.height -
                            uvy * this.ctx.canvas.height;

                        if (!this.lastPos) {
                            this.lastPos = { x, y };
                        }

                        this.textPos = { x, y };

                        this.lastPos = { x, y };

                        this.onCreateText();
                        break;
                    }
                    default: {
                    }
                }
            }
        });

        this.renderer.domElement.addEventListener("pointerup", () => {
            this.isDrawing = false;
            this.lastPos = undefined;
            this.onDrawStop();
        });

        new Hoverable(this.board, () => {
            if (this.mode === "drawing") {
                document.body.style.cursor = "crosshair";
            } else if (this.mode === "text") {
                document.body.style.cursor = "text";
            }
        });
    }

    remoteDraw = (params) => {
        this.ctx.strokeStyle = params.strokeStyle;
        this.ctx.beginPath();
        this.ctx.moveTo(params.lastPos.x, params.lastPos.y);
        this.ctx.lineTo(params.pos.x, params.pos.y);
        this.ctx.lineWidth = params.lineWidth;
        this.ctx.stroke();
        this.ctx.closePath();
        this.texture.needsUpdate = true;
    };

    remoteText = (params) => {
        this.ctx.font = `${params.fontSize}px Arial`;
        this.ctx.fillStyle = params.color;
        this.ctx.textAlign = "center";
        this.ctx.fillText(params.text, params.pos.x, params.pos.y);
        this.texture.needsUpdate = true;
    };

    undo = () => {
        const history = this.history.pop();
        if (!history) return;
    };

    getMouseX = (event) => {
        return (event.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
    };

    getMouseY = (event) => {
        return -(event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1;
    };

    getMousePoint = (e) => {
        let x = this.getMouseX(e);
        let y = this.getMouseY(e);

        this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera);
        const intersects = this.raycaster.intersectObject(this.board);

        if (intersects.length === 0) return;

        const intersection = intersects[0];

        if (intersection && intersection.uv && intersection.distance < this.drawDistance) {
            return {
                x: intersects[0].uv.x * this.width - this.width / 2,
                y: intersects[0].uv.y * this.height - this.height / 2,
                uvx: intersects[0].uv.x,
                uvy: intersects[0].uv.y,
            };
        }
    };

    setPosition = (x, y, z) => {
        this.board.position.set(x, y, z);
    };

    setRotation = (x, y, z) => {
        this.board.rotateY(y * THREE.MathUtils.DEG2RAD);
    };

    clear = (skipSend) => {
        this.ctx.fillStyle = "#FFF";
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);

        this.texture.needsUpdate = true;

        if (!skipSend)
            Multiplayer.instance.sendCustomEvent(this.uuid, "clear", [true]);
    };

    takeScreenshot = () => {
        const dataUrl = this.canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.setAttribute("href", dataUrl);
        link.setAttribute("target", "_blank");
        link.setAttribute(
            "download",
            `whiteboard-${new Date().toLocaleTimeString()}.png`
        );
        link.click();
    };

    enableDrawingMode = () => {
        this.mode = "drawing";
        this.lineWidth = 12;
    };

    enableEraser = () => {
        this.mode = "drawing";
        this.lineWidth = 60;
        this.color = new THREE.Color("white");
    };

    enableTextMode = () => {
        this.mode = "text";
        // console.log("Text mode enabled")
    };

    setCurrentTextValue = (value) => {
        this.ctx.font = `${this.fontSize}px Arial`;
        this.ctx.fillStyle = "#000";
        this.ctx.textAlign = "center";
        this.ctx.fillText(value, this.textPos.x, this.textPos.y);

        Multiplayer.instance.sendCustomEvent(this.uuid, "remoteText", {
            text: value,
            color: "#000",
            pos: this.textPos,
            fontSize: this.fontSize
        });

        this.texture.needsUpdate = true;

        //Set drawing mode when the text has been confirmed.
        this.enableDrawingMode();
        this.onCreateTextLeave();
    };

    deleteCurrentTextField = () => {
        // this.board.remove(this.currentTextContainer);
        // this.currentText = undefined;
        // this.currentTextContainer = undefined;
    };

    update = () => {
        if (this.isDrawing) this.texture.needsUpdate = true;
    };

    rotateY(angle) {
        this.board.rotateY(angle);
    }
}
