import {
  // Button,
  Dialog,
  // DialogTitle,
  // DialogActions,
  Box,
  Grid,
  // Link,
  DialogContent,
  Link,
  // useTheme,
  // useMediaQuery
} from "@mui/material";
// import React, { useEffect } from "react";
//import {RecordEvent} from "../libs/AnalyticsLib";
// import Link from "@material-ui/core/Link";
// import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@mui/material/IconButton";
// import Paper from '@material-ui/core/Paper';

// https://www.webfx.com/blog/web-design/examples-of-modal-windows/

export default function ModalOAContact(props) {
  const { open, onClose } = props;
  // const theme = useTheme();
  // const fullscreenRecommended = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      open={open}
      fullWidth //for mobile modal
      // fullScreen={fullscreenRecommended} //for mobile fullscreen
      // maxWidth="sm" //for mobile fullscreen

      // REF
      // xs, extra-small: 0px
      // sm, small: 600px
      // md, medium: 960px
      // lg, large: 1280px
      // xl, extra-large: 1920px

      sx={{
        "& .MuiDialog-paper": {
          // backgroundColor: "red",
          // backgroundColor: 'rgba(17,20,49,1.0)',
          backgroundColor: "rgb(17,20,49)",
          // backgroundImage: "url(" + "assets/images/banners/banner.jpg" + ")",
          // backgroundPosition: 'center',
          // backgroundSize: 'cover',
          // backgroundRepeat: 'no-repeat'
        },
      }}
    >
      <DialogContent
        sx={{
          maxWidth: "100vw",
          position: "relative",
        }}
      >
        <IconButton
          onClick={() => onClose()}
          sx={{
            color: "white",
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box py={1.0}></Box>
        <img src="assets/logos/zonevs-logo.svg" alt={""} width="100%" />

        <Grid container>
          {/*<Grid item xs={3}>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <div className="custom-text-Barlow-Regular-dialogBoxHeadline" align="left" justify="left">
              <Box py={1.0}></Box>
              Contact
            </div>
            <div className="custom-text-Barlow-Regular-dialogBox-small" align="left" justify="left">
              <Box py={1.0}></Box>
              <Link
                style={{ color: "#7cc9fd" }}
                href="https://hello.liquidavatar.com/contact-oasis?__hstc=19328871.c42a86bcf850966aa00d05fef780c01b.1649102250339.1649102250339.1649102250339.1&__hssc=19328871.1.1649102250339&__hsfp=4156890077"
                underline="always"
                target="_blank"
              >
                <Box py={0.5}></Box>
                https://hello.liquidavatar.com/contact-oasis
              </Link>
              <Box py={1.0}></Box>
              For more information about our gallery click:_
              <Link
                style={{ color: "#7cc9fd" }}
                href="https://oasisdigitalstudios.com/gallery/"
                underline="always"
                target="_blank"
              >
                here
              </Link>
              <Box py={2.0}></Box>
            </div>
          </Grid>
          {/*<Grid item xs={3}>*/}
          {/*</Grid>*/}
        </Grid>

        {/*<img src="assets/images/oasisBarcodePonies/Oasis-Logo-v1.png" alt={""} width="100%" />*/}
      </DialogContent>
    </Dialog>
  );
}
