///////////////////////////////////////////////
//
//Constellation
//
//https://openprocessing.org/sketch/434620
//
///////////////////////////////////////////////
import P5Sketch from "./P5Sketch";
import P5 from "p5";

//class variables
let EPSILON = 0.0000001;
let particleSize = 3;
let myWidth = 1;
let myHeight = 1;

///////////////////////////
class Star
{
    constructor(p5)
    {
        this.p5 = p5;

        this.a = this.p5.random(5 * this.p5.TAU); // "5*TAU" => render will be more homogeneous
        this.r = this.p5.random(this.p5.width * .4, this.p5.width * .25); // first position will looks like a donut
        this.loc = this.p5.createVector(this.p5.width / 2 + Math.sin(this.a) * this.r, this.p5.height / 2 + Math.cos(this.a) * this.r);
        this.speed = this.p5.createVector();
        this.speed = P5.Vector.random2D();
        //this.speed.random2D();
        this.bam = this.p5.createVector();
    }

    doTheThing(width, height)
    {
    }

    display(pos, particleSize)
    {
    }

    update(mouseX, mouseY)
    {
        this.bam = P5.Vector.random2D(); // movement of star will be a bit erractic
        //this.bam.random2D();
        this.bam.mult(0.45);
        this.speed.add(this.bam);


        // speed is done according distance between loc and the mouse :
        this.m = this.p5.constrain(this.p5.map(this.p5.dist(this.loc.x, this.loc.y, mouseX, mouseY), 0, this.p5.width, 8, .05), .05, 8);
        // constrain => avoid returning "not a number"



        this.speed.normalize().mult(this.m);

        // No colision detection, instead loc is out of bound
        // it reappears on the opposite side :
        if (this.p5.dist(this.loc.x, this.loc.y, this.p5.width / 2, this.p5.height / 2) > (this.p5.width / 2) * 0.98) {
            if (this.loc.x < this.p5.width / 2) {
                this.loc.x = this.p5.width - this.loc.x - 4; // "-4" => avoid blinking stuff
            } else if (this.loc.x > this.p5.width / 2) {
                this.loc.x = this.p5.width - this.loc.x + 4; // "+4"  => avoid blinking stuff
            }
            if (this.loc.y < this.p5.height / 2) {
                this.loc.y = this.p5.width - this.loc.y - 4;
            } else if (this.loc.x > this.p5.height / 2) {
                this.loc.y = this.p5.width - this.loc.y + 4;
            }
        }
        this.loc = this.loc.add(this.speed);
    }
}
/////////////////////////////


export default class ExampleSketch17
{
    //standard p5 stuff
    canvas = null;
    texture = null;
    p5 = null;
    width = 500;
    height = 500;
    mousePosition = { x: 0, y: 0 }
    onSetup = null;

    //sketch variables

    //array to hold multiple stars from the CLASS
    constellation = [];
    n;
    d;

    constructor(width, height) {
        if(width) this.width = width;
        if(height) this.height = height;
    }

    setup = (p5) =>  {
        const canvas = this.p5.createCanvas(this.width, this.height);
        canvas.canvas.style.display = 'none'
        canvas.parent("root");
        this.canvas = canvas.canvas;

        //needed?
        this.p5.pixelDensity(1); // Set 1 because it's too slow on firefox

        //number of stars
        this.n = 200;
        for (let i = 0; i <= this.n; i++) {
            this.constellation.push(new Star(this.p5));
        }

        //define drawing style
        this.p5.strokeWeight(.75);
        this.p5.stroke('#FFFFFF');



        //do we need this?
        this.r = this.width / 2;
        if(this.onSetup) this.onSetup(this);
    }

    draw = (p5) => {
        if(this.texture)
            this.texture.needsUpdate = true;
        this.p5.background(0);
        // console.log(this.p5.width)

        // console.log('hello');

        for (let i = 0; i < this.constellation.length; i++) {
            this.constellation[i].update(this.mousePosition.x, this.mousePosition.y);
            for (let j = 0; j < this.constellation.length; j++) {
                if (i > j) { // "if (i > j)" => to check one time distance between two stars
                    let d = this.constellation[i].loc.dist(this.constellation[j].loc); // Distance between two stars
                    if (d <= this.p5.width / 8) { // if d is less than width/10 px, we draw a line between the two stars
                        this.p5.line(this.constellation[i].loc.x, this.constellation[i].loc.y, this.constellation[j].loc.x, this.constellation[j].loc.y)
                    }
                }
            }
        }
    }


    sketch = (p5) => {
        this.p5 = p5;
        p5.init = this.init;
        p5.draw = this.draw;
        p5.setup = this.setup;
    }

    onClick () {
        this.p5.background(0);
    }

    updateMousePosition = (x, y) => {
        this.mousePosition = { x, y }
        // this.p5.background(this.mousePosition.x * 25,0,0);
        // this.p5.stroke(this.mousePosition.x * 25,0,0);
        // this.p5.background(x/10);
        // this.str_wei = x / 100;
    }
}

