import Component from "./Component";
import Multiplayer from "./MultiplayerSimple";
import * as THREE from "three";

export default class ScreenObject extends Component {
    constructor(uuid, scene, width, height, cloneScreen) {
        super();

        this.uuid = uuid;
        Multiplayer.instance.registerCustomEventHandler(this.uuid, this);

        const RAYCAST_EXCLUDE_LAYER = 31;

        const video = document.createElement("video");
        video.style.display = "none";
        video.id = this.uuid;
        video.setAttribute("webkit-playsinline", "webkit-playsinline");
        video.setAttribute("playsinline", "playsinline");
        const root = document.getElementById("root");
        root.appendChild(video);
        const plane = new THREE.PlaneGeometry(width || 6.4 * 0.89, height || 3.6 * 0.89);
        const texture = new THREE.VideoTexture(video);
        this.videoTexture = texture;

        const material = new THREE.ShaderMaterial({
            fragmentShader: this.fragmentShader(),
            vertexShader: this.vertexShader(),
            uniforms: {
                tex: { type: "t", value: texture },
                enabled: { value: false },
            },
            transparent: true,
        });

        this.videoMaterial = material;

        this.placeholderTexture = new THREE.TextureLoader().load("assets/textures/postit_1.png");

        window.toggleGreenScreenMode = () => {
            const enabled = !material.uniforms.enabled.value;
            this.toggleGreenScreen(enabled, true);
        };

        this.board = new THREE.Mesh(plane, material);
        this.board.traverse((x) => {
                x.layers.disableAll()
                x.layers.enable(RAYCAST_EXCLUDE_LAYER);
                })

        scene.add(this.board);
        video.play();
    }

    toggleGreenScreen(enabled, send) {
        this.videoMaterial.uniforms.enabled.value = enabled;
        if (send) {
            Multiplayer.instance.sendCustomEvent(
                this.uuid,
                "toggleGreenScreen",
                this.videoMaterial.uniforms.enabled.value
            );
        }
    }

    vertexShader() {
        return `
            varying vec2 vUv;

            void main() {
                vUv = uv;

                vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * modelViewPosition;
            }
        `;
    }

    fragmentShader() {
        return `
            uniform sampler2D tex;
            uniform bool enabled;
            varying vec2 vUv;

            void main () {
                mediump vec3 inColour = texture2D(tex, vUv).rgb;
                if (!enabled) {
                    gl_FragColor = vec4(inColour, 1.0);
                } else {
                    mediump vec3 green = vec3(0.0, 1.0, 0.0);
                    mediump float a = (length(inColour - green) - 0.5) * 7.0;
                    gl_FragColor = vec4(inColour, a);
                }
            }
        `;
    }

    setPosition(vect) {
        vect.copy(this.board.position);
    }

    setPlaceholderActive = (active) => {
        this.videoMaterial.uniforms.texture = active ? this.placeholderTexture : this.videoTexture;
        this.videoMaterial.needsUpdate = true;
    };
}
