import AgoraRTM, { RtmChannel, RtmClient, RtmMessage, RtmTextMessage } from "agora-rtm-sdk";
import { makeAutoObservable } from "mobx";
import LiteEvent from "../libs/LiteEvent";
import App from "./App";
import { v4 } from "uuid";

const appId = "b788346730fa4d65b529a644ee50b320";

export interface ChatThread {
    // The UID of the remote user
    uid: string,
    avatar: string;
    name: string;
    messages: ChatMessage[],
    latestMessage?: ChatMessage,
    read?: boolean
}

export interface ChatMessage {
    sender: User,
    message: string
    timestamp: string
}

export interface User {
    uid: string,
    avatar: string;
    username: string
}

export default class ChatClient {

    uid: string;
    rtm_client!: RtmClient;
    rtm_channel!: RtmChannel

    onMemberJoined: LiteEvent<string> = new LiteEvent<string>()
    onMemberLeft: LiteEvent<string> = new LiteEvent<string>()
    onMessage: LiteEvent<ChatMessage> = new LiteEvent<ChatMessage>()

    users: Map<string, User> = new Map<string, User>();
    threads: Map<string, ChatThread> = new Map<string, ChatThread>();

    constructor() {
        makeAutoObservable(this);

        let uid = localStorage.getItem('chat-uid');
        if(!uid){
            uid = v4();
            localStorage.setItem('chat-uid', uid);
        }

        this.uid = uid;
    }

    async join(channelId: string){
        this.rtm_client = AgoraRTM.createInstance(appId, {
            enableLogUpload: false,
            logFilter: AgoraRTM.LOG_FILTER_ERROR,
        });

        await this.rtm_client.login({
            uid: this.uid
        })

        await this.rtm_client.setLocalUserAttributes(App.userAttributes)

        this.rtm_channel = this.rtm_client.createChannel(channelId);
        this.handleRtmEvents();

        await this.rtm_channel.join();

        // Populate the user list
        await this.updateMembersCache();

        console.info('Chat client joined.')
    }

    private getLocalUser(): User {
        return {
            uid: this.uid,
            avatar: App.userAttributes.avatar,
            username: App.userAttributes.username
        }
    }

    private async updateMembersCache(){
        const users = await this.rtm_channel.getMembers();
        const updated = new Map<string, User>();

        for(const uid of users){
            if(this.uid === uid) continue;
            const attributes = await this.rtm_client.getUserAttributes(uid);
            const user: User = {
                uid,
                avatar: attributes['avatar'],
                username: attributes['username']
            }
            updated.set(uid, user);
        }
        this.users = updated;
    }

    private handleRtmEvents = () => {
        // event listener for receiving a channel message
        this.rtm_channel?.on("ChannelMessage", async (event, sender_id) => {
            event = event as RtmTextMessage;
            // convert from string to JSON
            const msg = JSON.parse(event.text);
        });

        this.rtm_channel.on("MemberJoined", async (uid) => {
            await this.updateMembersCache();
            this.onMemberJoined.trigger(uid);
        });

        this.rtm_channel.on("MemberLeft", async (uid) => {
            await this.updateMembersCache();
            this.onMemberLeft.trigger(uid);
        });

        this.rtm_client.on("MessageFromPeer", async (message: RtmMessage, peerId: string, messageProps) => {
            const msg = message as RtmTextMessage;
            const msgData: ChatMessage = JSON.parse(msg.text);
            this.addMessageToThread(peerId, msgData)
            this.onMessage.trigger(msgData);
        })
    };

    createThread(uid: string){
        return this.getThread(uid);
    }

    getThread(uid: string) {
        let exists = this.threads.has(uid);
        if(!exists){
            const user = this.getUser(uid);
            const thread = {
                uid,
                messages: [],
                avatar: user.avatar,
                name: user.username,
                latestMessage: undefined
            }
            this.threads.set(uid, thread)
        }
        return this.threads.get(uid)!;
    }

    private addMessageToThread(uid: string, message: ChatMessage){
        const thread = this.getThread(uid);
        thread.read = false;
        thread.messages.push(message);
        thread.latestMessage = message;
    }

    public getUser(uid: string){
        const user = this.users.get(uid);
        if(!user) throw new Error("Remote user not found in local cache");
        return user;
    }

    async sendMessage(uid: string, message: string){

        const chatMessage: ChatMessage = {
            sender: this.getLocalUser(),
            timestamp: new Date().toISOString(),
            message,
        }

        this.addMessageToThread(uid, chatMessage)

        const rtmMessage: RtmTextMessage = {
            text: JSON.stringify(chatMessage)
        }

        await this.rtm_client.sendMessageToPeer(rtmMessage, uid)
    }

    sendChannelMessage = async (action: string, data: any) => {
        if (this.rtm_channel) {
            // Build the Agora RTM Message
            const msg: RtmMessage = {
                description: undefined,
                messageType: "TEXT",
                rawMessage: undefined,
                text: JSON.stringify({
                    action,
                    data,
                }),
            };

            try {
                await this.rtm_channel.sendMessage(msg);
            } catch (err) {
                console.log("Error sending channel message.");
                console.error(err);
            }
        }
    };
}