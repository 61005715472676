///////////////////////////////////////////////
//
//3D Typography Study
//
//https://openprocessing.org/sketch/647596
//
///////////////////////////////////////////////
import P5Sketch from "./P5Sketch";
import P5 from "p5";


//damn why cant i declare a class
//to translate - put a this. before EVERYTHING here

let font;

class Type
{
    constructor(p5, _str, _x, _y, _z)
    {
        this.p5=p5;
        this.str = _str;
        this.x = _x;
        this.y = _y;
        this.z = _z;
    }
    update(width) {
        this.z += 10;
        if(this.z > width/2){
            this.z = -width*5;
        }
    }

    display(CENTER) {
        this.p5.push();
        this.p5.translate(this.x, this.y, this.z);
        this.p5.textAlign(CENTER, CENTER);
        this.p5.textFont(font);
        this.p5.textSize(100);
        this.p5.fill(0,0,100);
        this.p5.text(this.str, 0, 0);
        this.p5.pop();
    }
}

export default class ExampleSketch13
{
    //standard p5 stuff
    canvas = null;
    texture = null;
    p5 = null;
    width = 500;
    height = 500;
    mousePosition = { x: 0, y: 0 }
    onSetup = null;
    grow;
    //sketch variables
    str = "The quick brown fox jumps over the lazy dog";
    //todo - can't declare this properly here??
    str_arr = [];
    // font;

    constructor(width, height) {
        if(width) this.width = width;
        if(height) this.height = height;
    }

    setup = (p5) =>  {
        const canvas = this.p5.createCanvas(this.width, this.height);
        canvas.canvas.style.display = 'none'
        canvas.parent("root");
        this.canvas = canvas.canvas;

        // this.p5.colorMode(HSB, 360, 100, 100, 100);
        let strs = this.str.split(" ");
        for (let i = 0; i < strs.length*20; i++) {
            let x = this.p5.random(-this.width / 2, this.width / 2);
            let y = this.p5.random(-this.height / 2, this.height / 2);
            let z = this.p5.random(-this.width*5, this.width/2);

            //todo can't define type / had to declare str_arr here - not up top
            this.str_arr.push(new Type(this.p5, strs[i%strs.length], x, y, z));
        }


        font = this.p5.loadFont("assets/fonts/Mark/MarkForMC-Bold.ttf");
        this.p5.textFont(font);
        this.p5.textSize(this.width / 30);
        this.p5.textAlign(this.CENTER, this.CENTER);
        this.p5.fill(255);

        if(this.onSetup) this.onSetup(this);
    }

    draw = (p5) => {
        if(this.texture)
            this.texture.needsUpdate = true;

        this.p5.background(0,0,0);

        this.grow += .0001;
        if(this.grow > this.width/2){
            this.grow = -this.width*5;
        }

        this.p5.textSize(  (this.width / 3) + this.grow);
        //3d - so... no
        // this.p5.orbitControl();
        //todo can't get length - because could not finish setup
        for (let i = 0; i < this.str_arr.length; i++) {
            this.str_arr[i].update(this.width);
            this.str_arr[i].display(this.CENTER);
        }

        // this.p5.text('oooooooooooooooooo', this.width/2, this.height/2);
    }

    sketch = (p5) => {
        this.p5 = p5;
        p5.init = this.init;
        p5.draw = this.draw;
        p5.setup = this.setup;
    }

    onClick () {
        this.p5.background(0);
    }

    updateMousePosition = (x, y) => {
        this.mousePosition = { x, y }
    }

}

