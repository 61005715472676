///////////////////////////////////////////////
//
//MLK Speech
//
//https://openprocessing.org/sketch/1041737
//
///////////////////////////////////////////////
import P5Sketch from "./P5Sketch";
import P5 from "p5";
export default class ExampleSketch14
{
    //standard p5 stuff
    canvas = null;
    texture = null;
    p5 = null;
    width = 500;
    height = 500;
    mousePosition = { x: 0, y: 0 }
    onSetup = null;

    //sketch variables
    img;
    speech;
    row;
    col;
    interval;
    count;
    index = 0;
    shift=0;
    letters = [];
    xfactor = 0;
    yfactor = 0;
    bg = 0;
    bgt = 0;

    constructor(width, height) {
        if(width) this.width = width;
        if(height) this.height = height;
    }

    setup = (p5) =>  {
        const canvas = this.p5.createCanvas(this.width, this.height);
        canvas.canvas.style.display = 'none'
        canvas.parent("root");
        this.canvas = canvas.canvas;

        this.img = this.p5.loadImage("assets/images/nfts/nft_02.jpg");

        if(this.onSetup) this.onSetup(this);
    }

    draw = (p5) => {
        if(this.texture)
            this.texture.needsUpdate = true;

        this.p5.angleMode(this.DEGREES);
        this.img.resize(this.height,0);
        // getSpeech();
        this.p5.colorMode(this.p5.HSB, 255);
        this.count=5;
        this.p5.background(0);
        this.p5.textFont('courier');
        this.p5.textAlign(this.LEFT, this.CENTER);
        this.interval = this.img.height/72;
        this.row = 0;
        this.col = 0;
        this.count = 10;

        // for(let i=0; i<speech.length; i++){
        //     nextChar(i);
        //     col+=1;
        //     if(col==70) {
        //         col=0;
        //         row+=1;
        //     }
        // }

        // dreamsLast();
        // drawSpeech();

        let font = this.p5.loadFont("assets/fonts/Mark/MarkForMC-Bold.ttf");
        this.p5.textFont(font);
        this.p5.text('oooooooooooooooooo', this.width/2, this.height/2);
    }

    sketch = (p5) => {
        this.p5 = p5;
        p5.init = this.init;
        p5.draw = this.draw;
        p5.setup = this.setup;
    }

    nextChar(i) {
    // let y=12+row*interval;
    // let x=col*interval;
    // index=(70*row)+col;
    // let c = img.get(x,y);
    // let f = brightness(c);
    // if (f<=0) f=200;
    // let letter1 = speech.charAt(index).toLowerCase();
    // let letter2 = speech.charAt(index+1);
    // let letter3 = speech.charAt(index+3);
    // if(letter1=='d' && letter2=='r' && letter3=='a') count=0;
    // if(count<5) {
    //     f = 'firebrick';
    //     count+=1;
    // }
    // letters.push( new Letter(x,y, letter1, 8, f, count) );
}

    onClick () {
        // bgt = 255-bgt;
        // drawSpeech();
    }

    updateMousePosition = (x, y) => {
        this.mousePosition = { x, y }

        // bg = lerp(bg, bgt, 0.05);
        // drawSpeech();
    }





    drawSpeech(){
    // background(0)
    // fill(bg);
    // rect(0,0,width, height);
    // xfactor = map(mouseX,0,width,10,40);
    // yfactor = map(mouseY,0,height,-150,150);
    // for(let l of letters){
    //     l.morph();
    //     l.show();
    // }
}
    dreamsLast()
    {
        // for (let i = letters.length - 1; i > 0; i--)
        // {
        //     if (letters[i].f == 'firebrick')
        //     {
        //         letters.push(letters.splice(i, 1)[0]);
        //     }
        // }
    }
}

