import * as THREE from "three";
import Clickable from "./Clickable";
import Hoverable from "./Hoverable";
// import {RecordEvent} from "../libs/AnalyticsLib";

export default class MovePad {
    static currentPad;
    static pads = [];
    static heroPads = [];
    static iconScale = 0.7;

    constructor(hero, scene, controls, xRot, scale, colour, spriteImage, clickAreaScale, instant, hint, urlAndDisconnect, padImage, hideSprite) {
        this.hero = hero;
        this.controls = controls;
        this.rotateTo = null;
        this.xRot = xRot || -90.0;
        this.scale = scale || 0.6;
        this.colour = colour || 84154;
        this.hideSprite = true;

        //not working
        this.hint = hint || "Click here to move to this area";
        //not working
        this.spriteImage = spriteImage || "assets/icons/teleport.png";
        this.moveInstant = instant;
        this.overrideUrl = urlAndDisconnect;

        this.padImage = padImage || "assets/icons/teleport.png";




        //make all these unlit
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //create a basic flat colour geometry pad
        //create a textured or the flat pad:
        // this.loader.load("assets/icons/ZoneVS-arrow-Wb.png", (texture) => {
        //     const material = new THREE.MeshBasicMaterial({
        //         map: texture,
        //     });

        const geometry = new THREE.CircleGeometry(this.scale*.45, 32);
        // const material = new THREE.MeshBasicMaterial({
        //     color: "rgb(0, 127, 255)",
        //     opacity: 1.0,
        //     transparent: true,
        // });

        const material = new THREE.MeshBasicMaterial({
            map: new THREE.TextureLoader().load(this.padImage),
            transparent: true,
        });
        const circle = new THREE.Mesh(geometry, material);
        circle.rotateX(this.xRot * THREE.MathUtils.DEG2RAD);
        scene.add(circle);
        this.object = circle;
        new Clickable(circle, this.handleClick);
        MovePad.pads.push(this);
        if (this.hero) {
            MovePad.heroPads.push(this);
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // this.loader.load(src,
        //     (texture) => {
        //         // const material = new THREE.MeshBasicMaterial({map: texture});
        //         const material = new THREE.ShaderMaterial({
        //             fragmentShader: this.fragmentShader(),
        //             vertexShader: this.vertexShader(),
        //             uniforms: {tex: {type: 't', value: texture}}
        //         });
        //         this.board.material = material;
        //         this.board.material.needsUpdate = true;
        //     }
        // );
        // this.loader = new THREE.TextureLoader();

        //create a textured sprite:
        const mySpriteMaterial = new THREE.SpriteMaterial({
            map: new THREE.TextureLoader().load(this.spriteImage),
            transparent: true,
        });
        const sprite = new THREE.Sprite(mySpriteMaterial);
        scene.add(sprite);
        sprite.scale.setScalar(this.scale)
        // sprite.scale.set(iconScale, iconScale, iconScale);
        this.objectSprite = sprite;
        new Clickable(sprite, this.handleClick);

        //this is just used for debugging to observe the clickable boundaries
        // const clickableSpriteMat = new THREE.SpriteMaterial({ transparent: true, opacity: .5, color: "red" });
        // const clickableSprite = new THREE.Sprite(clickableSpriteMat);
        // sprite.add(clickableSprite);
        // this.clickableSprite = clickableSprite;
        // new Clickable(clickableSprite, this.handleClick);

        const showHelp = () => {
            document.getElementById("help-text").style.display = "block";
            document.getElementById("help-text").innerHTML =
                this.hint;
            // "Click here to move to this area";
        };

        const hideHelp = () =>
            (document.getElementById("help-text").style.display = "none");

        const handleHover = () => {
            this.objectSprite.scale.setScalar(this.scale*1.1);
            document.body.style.cursor = "pointer";
            showHelp();
        }

        const handleUnhover = () => {
            this.objectSprite.scale.setScalar(this.scale);
            hideHelp();
        }

        new Hoverable(
            circle,
            handleHover,
            handleUnhover
        );
        new Hoverable(
            sprite,
            handleHover,
            handleUnhover
        );
        // new Hoverable(
        //     clickableSprite,
        //     handleHover,
        //     handleUnhover
        // );
    }

    set position(position) {
        this.object.position.set(position.x, position.y, position.z);
            this.objectSprite.position.set(
                position.x,
                position.y + 0.35,
                position.z
            );

    }

    setRotation = (x, y, z) => {
        this.object.rotateZ(45 * THREE.MathUtils.DEG2RAD);
    };

    get position() {
        return this.object.position;
    }

    handleClick = () => {

        if(this.overrideUrl){
            return window.open(this.overrideUrl, "_self")
        }

        MovePad.currentPad = this;
        // const myParameter = 42;
        // console.log(`DEBUG myParameter in MovePad.js = ${myParameter}`);

        //I wanted to see if I could pass a parameter to moveto to tell it what viewpoint to choose but it's not needed
        // this.controls.moveTo(new THREE.Vector3(this.object.position.x, this.object.position.y + 1.6, this.object.position.z), this.rotateTo, myParameter);
        //this.controls.moveTo(new THREE.Vector3(this.object.position.x, this.object.position.y + 1.6, this.object.position.z), this.rotateTo, this.rotateToViewSouth, this.rotateToViewEast, this.rotateToViewWest );

        if (!this.offsetPosition) {
            this.controls.moveTo(
                new THREE.Vector3(
                    this.object.position.x,
                    this.object.position.y + 1.6,
                    this.object.position.z
                ),
                this.rotateTo,
                this.rotateToViewSouth,
                this.rotateToViewEast,
                this.rotateToViewWest,
                this.moveInstant
            );
        } else {
            this.controls.moveTo(
                new THREE.Vector3(
                    this.offsetPosition.x,
                    this.offsetPosition.y + 1.6,
                    this.offsetPosition.z
                ),
                this.rotateTo,
                this.rotateToViewSouth,
                this.rotateToViewEast,
                this.rotateToViewWest,
                this.moveInstant
            );
        }
    };

    vertexShader() {
        return `
            varying vec2 vUv;

            void main() {
                vUv = uv;

                vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * modelViewPosition;
            }
        `;
    }

    fragmentShader() {
        return `
            uniform sampler2D tex;
            varying vec2 vUv;

            void main() {
                gl_FragColor = texture2D(tex, vUv);
            }
        `;
    }

    static getCurrentPad() {
        if (!this.currentPad) {
            return MovePad.pads[0];
        } else {
            return MovePad.currentPad;
        }
    }

    set clickableAreaScale(scalar){
        if(this.clickableSprite)
            this.clickableSprite?.scale.setScalar(scalar)
    }
}
