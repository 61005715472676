import * as THREE from "three";
import Hoverable from "./Hoverable";
import MultiplayerSimple  from "./MultiplayerSimple.ts";
import Component from "./Component";
import App from "../core/App";
import AgoraRTC from "agora-rtc-sdk-ng";
import BelongService from "../core/BelongService";

export default class BelongPicture extends Component
{
    raycaster = new THREE.Raycaster();
    width = 3;
    height = 3;
    heightmap = null;
    inRange = true;
    activeDistance = 15;

    belongService = new BelongService();

    constructor({
                    uuid,
                    scene,
                    images,
                    payload,
                    camera,
                    renderer,
                    width,
                    height
                })
    {
        super();

        this.uuid = uuid;
        this.payload = payload;

        const root = document.getElementById("root");
        const canvas = document.createElement("canvas");
        canvas.style.display = "none";
        this.canvas = canvas;
        root.appendChild(canvas);

        canvas.id = uuid;
        this.ctx = canvas.getContext("2d");
        this.ctx.canvas.width = width * 1000;
        this.ctx.canvas.height = height * 1000;
        this.ctx.fillStyle = "#000";
        this.ctx.fillRect(0, 0, canvas.width, canvas.height);

        this.scene = scene;
        this.images = images;
        this.camera = camera;
        this.renderer = renderer;

        this.width = width || 3;
        this.height = height || 3;

        this.loader = new THREE.TextureLoader();

        this.geometry = new THREE.PlaneBufferGeometry(this.width * .96, this.height);
        const texture = new THREE.CanvasTexture(this.ctx.canvas);
        this.texture = texture;
        this.material = new THREE.MeshBasicMaterial({ map: texture });

        this.board = new THREE.Mesh(this.geometry, this.material);

        this.setup(this.payload);

        scene.add(this.board);
    }

    getMouseX = (event) =>
    {
        return (event.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
    };

    getMouseY = (event) =>
    {
        return -(event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1;
    };

    setup = (payload) => {
        if(payload.startsWith('data:image'))
            this.setupPicture(payload);
        else
            this.setupPictureFromUrl(payload);
    }

    setupPictureFromUrl = (payload) => {
        fetch(payload, {mode: 'no-cors', redirect: 'follow'})
            .then(r => r.blob())
            .then(async (x) => {
                let dataUrl = await new Promise(resolve => {
                    let reader = new FileReader();
                    reader.onload = () => resolve(reader.result);
                    reader.readAsDataURL(x);
                });

                const ctx = this.ctx;
                const texture = this.texture;
                const img = new window.Image();

                img.addEventListener("load", () =>
                {
                    ctx.drawImage(img,
                        0,
                        0,
                        ctx.canvas.width,
                        ctx.canvas.height);
                    texture.needsUpdate = true;
                });

                img.setAttribute("src", dataUrl);
            })
    }
    setupPicture = (payload) =>
    {
        const ctx = this.ctx;
        const texture = this.texture;
        const img = new window.Image();

        img.addEventListener("load", () =>
        {
            ctx.drawImage(img,
                0,
                0,
                ctx.canvas.width,
                ctx.canvas.height);
            texture.needsUpdate = true;
        });

        img.setAttribute("src", payload);
    };

    setPosition = (x, y, z) =>
    {
        this.board.position.set(x, y, z);
    };

    setRotation = (x, y, z) =>
    {
        this.board.rotateY(y * THREE.MathUtils.DEG2RAD);
    };

    rotateY(angle)
    {
        this.board.rotateY(angle);
    }
}
