///////////////////////////////////////////////
//
//Typehover
//
//https://openprocessing.org/sketch/760047
//
///////////////////////////////////////////////
/**
 * draw tool. shows how to draw with dynamic elements.
 *
 * MOUSE
 * drag                : draw with text
 *
 * KEYS
 * del, backspace      : clear screen
 * arrow up            : angle distortion +
 * arrow down          : angle distortion -
 * s                   : save png

 */
import P5Sketch from "./P5Sketch";
import P5 from "p5";
export default class ExampleSketch9
{
    //standard p5 stuff
    canvas = null;
    texture = null;
    p5 = null;
    width = 1500;
    height = 500;
    mousePosition = { x: 0, y: 0 }
    onSetup = null;

    //sketch variables
    x = 0;
    y = 0;
    stepSize = 5.0;
    letters = "BELONGING TOGETHERNESS DIVERSITY UNIQUENESS EXPRESSION IDENTITY";
    fontSizeMin = 15;
    angleDistortion = 0.0;
    counter = 0;
    can_write = 1;
    can_writeBool = true;
    textSizeMax=0.0;


    constructor(width, height) {
        if(width) this.width = width;
        if(height) this.height = height;
    }

    setup = (p5) =>  {
        const canvas = this.p5.createCanvas(this.width, this.height);
        canvas.canvas.style.display = 'none'
        canvas.parent("root");
        this.canvas = canvas.canvas;

        // this.p5.background(255);
        // this.p5.smooth();

        //??? can we define cursor here? probably not
        // this.p5.cursor(CROSS);

        this.x = this.mousePosition.x;
        this.y = this.mousePosition.y;

        let fontMark = this.p5.loadFont("assets/fonts/Mark/MarkForMC-Bold.ttf");
        this.p5.textFont(fontMark);
        this.p5.textAlign(this.LEFT);

        this.p5.fill(255);
        this.p5.background(10,10,10);

        //todo - needed??
        this.r = this.width / 2;
        if(this.onSetup) this.onSetup(this);
    }

    draw = (p5) => {
        //I think this is always needed
        if(this.texture)
            this.texture.needsUpdate = true;

        //we add the mouseloc here
        let mouseX = this.mousePosition.x*this.width;
        let mouseY = this.mousePosition.y*this.height;

            // if (this.mouseOver) {
        if (this.can_writeBool) {
            let d = this.p5.dist(this.x,this.y, mouseX,mouseY);
            this.p5.textSize(this.fontSizeMin + d / 2);

            if (this.p5.textSize(this.fontSizeMin + d / 2)>=this.textSizeMax)
            {
                this.textSizeMax = this.p5.textSize(this.fontSizeMin + d / 2);
            }


            let newLetter = this.letters.charAt(this.counter);
            this.stepSize = this.p5.textWidth(newLetter);

            // console.log("george textSizeMax" + this.textSizeMax);
            // console.log("george textsize" + this.fontSizeMin+d/2);
            // console.log("george dist_d" + d);
            // console.log("george stepsize" + this.stepSize);

            // does not work I think it's to do with dist
            if (d > this.stepSize) {
                let angle = this.p5.atan2(mouseY-this.y, mouseX-this.x);

                this.p5.push();
                this.p5.translate(this.x, this.y);
                this.p5.rotate(angle + this.p5.random(this.angleDistortion));
                this.p5.text(newLetter, 0, 0);
                this.p5.pop();

                this.counter++;
                if (this.counter > this.letters.length-1) this.counter = 0;

                this.x = this.x + this.p5.cos(angle) * this.stepSize;
                this.y = this.y + this.p5.sin(angle) * this.stepSize;
            }
        }
    }

    sketch = (p5) => {
        this.p5 = p5;
        p5.init = this.init;
        p5.draw = this.draw;
        p5.setup = this.setup;
    }

    onClick () {
        this.can_write = 0;
        this.can_writeBool = true;

        this.p5.background(this.mousePosition.x * 25,0,0);
        // this.p5.textSize(50)
        // this.p5.fill(255);
        // this.p5.text('oooooooooooooooooo', this.mousePosition.x, this.height/2);
    }

    //there is no unclick
    onUnClick () {
        this.can_write = 1;
    }

    // mouseOver () {
    //     //???
    //     let x = this.mousePosition.x;
    //     let y = this.mousePosition.y;
    // }


    // keyTyped() {
    // if (key == 's' || key == 'S') save("P_2_3_3_01.png");
    // }

//     keyPressed() {
//     angleDistortion ctrls arrowkeys up/down
//     if (keyCode == DELETE || keyCode == BACKSPACE) background(255);
//     if (keyCode == UP_ARROW) angleDistortion += 0.1;
//     if (keyCode == DOWN_ARROW) angleDistortion -= 0.1;
// }

    // updateMousePosition = (x, y) => {
    //     this.mousePosition = { x:x*this.width, y:y*this.height }
    //     // console.log("george_xpos_inupdateMousePosition" + this.mousePosition.x);
    //     // console.log("george_ypos_inupdateMousePosition" + this.mousePosition.y);
    // }
}

