import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import LoadingManager from "../utilities/LoadingManager";

export default class HDREnvironment
{
    constructor(scene, renderer, path)
    {
        const pmremGenerator = new THREE.PMREMGenerator(renderer);
        pmremGenerator.compileEquirectangularShader();

        const loader = new RGBELoader(LoadingManager.instance)
        // .setDataType(THREE.UnsignedByteType)

        const load = async () =>
        {
            const texture = await loader.loadAsync(path);
            const envMap =
                pmremGenerator.fromEquirectangular(texture).texture;

            scene.background = envMap;
            scene.environment = envMap;

            texture.dispose();
            pmremGenerator.dispose();
        }

        load();
    }
}
