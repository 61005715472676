import { Button, DialogActions } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import React from "react";

function DialogIncompatibleMobil(props) {
  const handleClose = () => {
    props.onClose();
  };

  return (
    <Dialog {...props}>
      <DialogTitle>Incompatible Device</DialogTitle>
      <DialogContent>
        Please use a laptop or desktop device for the best experience. Mobile may deliver reduced site performance.
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogIncompatibleMobil;
