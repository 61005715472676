import * as THREE from "three";
import Clickable from "./Clickable";
import Multiplayer from "./MultiplayerSimple";
import Hoverable from "./Hoverable";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

export default class VideoViewerCustom {
    static viewers = [];
    currentIndex = 0;

    width = 1;
    height = 1;

    constructor(uuid, scene, listener, poster, modelUrl, videoFaceName, yRot, voting) {
        this.uuid = uuid;
        this.scene = scene;

        Multiplayer.instance.registerCustomEventHandler(this.uuid, this);
        VideoViewerCustom.viewers.push(this);

        this.model = new THREE.Object3D();
        scene.add(this.model);

        this.textureLoader = new THREE.TextureLoader();

        const video = document.getElementById(uuid);
        this.video = video;
        this.video.onended = () => {
            console.log('End')
            this.sadButtonEnd.visible = true;
            this.mehButtonEnd.visible = true;
            this.happyButtonEnd.visible = true;
            this.playButton.visible = true;
            this.pauseButton.visible = false;
        }

        const buttonSize = 1.0;

        const positionalAudio = new THREE.PositionalAudio(listener);
        positionalAudio.play();
        positionalAudio.setMediaElementSource(video);
        positionalAudio.setRefDistance(8);
        positionalAudio.setMaxDistance(10);
        positionalAudio.setVolume(1.0);
        positionalAudio.setRolloffFactor(5);
        positionalAudio.setDirectionalCone(180, 230, 0.1);
        this.positionalAudio = positionalAudio;

        this.model.add(positionalAudio);

        // const helper = new PositionalAudioHelper(positionalAudio, 2);
        // helper.layers.disableAll();
        // helper.layers.enable(RAYCAST_EXCLUDE_LAYER);
        // positionalAudio.add(helper);

        scene.add(this.model);


        this.textureLoader.load("assets/icons/ZoneVS-pause.png", (texture) => {
            const material = new THREE.MeshBasicMaterial({
                map: texture,
            });
            // const geometry = new THREE.PlaneBufferGeometry(buttonSize, buttonSize);
            const geometry = new THREE.CircleGeometry(buttonSize * 0.5, 32);

            const controlA = new THREE.Mesh(geometry, material);
            // controlA.position.x =-(this.width / 2) - (buttonSize / 2);
            // controlA.position.y =  -(buttonSize / 2);
            controlA.position.x = 0;
            controlA.position.y = -(this.height / 1.9) - buttonSize / 2 - 3.0;
            controlA.position.z = -(-0.025);
            controlA.rotateY(yRot * THREE.MathUtils.DEG2RAD);
            this.model.add(controlA);
            new Clickable(controlA, () => this.pause());
            this.pauseButton = controlA;
            this.pauseButton.visible = false;
        });

        this.textureLoader.load("assets/icons/ZoneVS-play.png", (texture) => {
            const material = new THREE.MeshBasicMaterial({
                map: texture,
            });
            // const geometry = new THREE.PlaneBufferGeometry(buttonSize, buttonSize);
            const geometry = new THREE.CircleGeometry(buttonSize / 2, 32);
            const controlB = new THREE.Mesh(geometry, material);
            // controlB.position.x = -(this.width / 2) - (buttonSize / 2);
            controlB.position.x = 0;
            controlB.position.y = 0;
            controlB.position.z = -(-0.025);
            controlB.rotateY(yRot * THREE.MathUtils.DEG2RAD);
            this.model.add(controlB);
            new Clickable(controlB, () => this.play());
            this.playButton = controlB;
            const showHelp = () => {
                document.getElementById("help-text").style.display = "block";
                document.getElementById("help-text").innerHTML = "Click here to play a Video in the meeting";
            };
            const hideHelp = () => (document.getElementById("help-text").style.display = "none");

            new Hoverable(
                this.playButton,
                () => {
                    document.body.style.cursor = "pointer";
                    this.playButton.scale.set(1.1, 1.1, 1.1);
                    showHelp();
                },
                () => {
                    this.playButton.scale.set(1.0, 1.0, 1.0);
                    hideHelp();
                }
            );
        });
//////////////////voting
        if (voting)
        {
            this.textureLoader.load("assets/icons/ZoneVS-sad.png", (texture) =>
            {
                const material = new THREE.MeshBasicMaterial({
                    map: texture,
                });
                const geometry = new THREE.CircleGeometry(buttonSize / 3, 32);
                const controlX = new THREE.Mesh(geometry, material);
                // controlB.position.x = -(this.width / 2) - (buttonSize / 2);
                controlX.position.x = 0;
                controlX.position.y = -(this.height / 1.9) - buttonSize / 2 - 3.0;
                controlX.position.z = -5;
                controlX.rotateY(yRot * THREE.MathUtils.DEG2RAD);
                this.model.add(controlX);
                new Clickable(controlX, () => this.voteSad());
                this.sadButton = controlX;
                this.sadButton.visible = false;
                const showHelp = () =>
                {
                    document.getElementById("help-text").style.display = "block";
                    document.getElementById("help-text").innerHTML = "Click if you are not relaxed";
                };
                const hideHelp = () => (document.getElementById("help-text").style.display = "none");

                new Hoverable(
                    this.sadButton,
                    () =>
                    {
                        document.body.style.cursor = "pointer";
                        this.sadButton.scale.set(1.1, 1.1, 1.1);
                        showHelp();
                    },
                    () =>
                    {
                        this.sadButton.scale.set(1.0, 1.0, 1.0);
                        hideHelp();
                    }
                );
            });
            /////////////////////////////////
            this.textureLoader.load("assets/icons/ZoneVS-meh.png", (texture) =>
            {
                const material = new THREE.MeshBasicMaterial({
                    map: texture,
                });
                const geometry = new THREE.CircleGeometry(buttonSize / 3, 32);
                const controlX = new THREE.Mesh(geometry, material);
                // controlB.position.x = -(this.width / 2) - (buttonSize / 2);
                controlX.position.x = 0;
                controlX.position.y = -(this.height / 1.9) - buttonSize / 2 - 3.0;
                controlX.position.z = -4;
                controlX.rotateY(yRot * THREE.MathUtils.DEG2RAD);
                this.model.add(controlX);
                new Clickable(controlX, () => this.voteMeh());
                this.mehButton = controlX;
                this.mehButton.visible = false;
                const showHelp = () =>
                {
                    document.getElementById("help-text").style.display = "block";
                    document.getElementById("help-text").innerHTML = "Click if you feel the same";
                };
                const hideHelp = () => (document.getElementById("help-text").style.display = "none");

                new Hoverable(
                    this.mehButton,
                    () =>
                    {
                        document.body.style.cursor = "pointer";
                        this.mehButton.scale.set(1.1, 1.1, 1.1);
                        showHelp();
                    },
                    () =>
                    {
                        this.mehButton.scale.set(1.0, 1.0, 1.0);
                        hideHelp();
                    }
                );
            });
        //////
            this.textureLoader.load("assets/icons/ZoneVS-happy.png", (texture) =>
            {
                const material = new THREE.MeshBasicMaterial({
                    map: texture,
                });
                const geometry = new THREE.CircleGeometry(buttonSize / 3, 32);
                const controlX = new THREE.Mesh(geometry, material);
                // controlB.position.x = -(this.width / 2) - (buttonSize / 2);
                controlX.position.x = 0;
                controlX.position.y = -(this.height / 1.9) - buttonSize / 2 - 3.0;
                controlX.position.z = -3;
                controlX.rotateY(yRot * THREE.MathUtils.DEG2RAD);
                this.model.add(controlX);
                new Clickable(controlX, () => this.voteHappy());
                this.happyButton = controlX;
                this.happyButton.visible = false;
                const showHelp = () =>
                {
                    document.getElementById("help-text").style.display = "block";
                    document.getElementById("help-text").innerHTML = "Click if you feel relaxed";
                };
                const hideHelp = () => (document.getElementById("help-text").style.display = "none");

                new Hoverable(
                    this.happyButton,
                    () =>
                    {
                        document.body.style.cursor = "pointer";
                        this.happyButton.scale.set(1.1, 1.1, 1.1);
                        showHelp();
                    },
                    () =>
                    {
                        this.happyButton.scale.set(1.0, 1.0, 1.0);
                        hideHelp();
                    }
                );
            });
        }
//////////////////
        ////////////////
        if (voting)
        {
            this.textureLoader.load("assets/icons/ZoneVS-sad.png", (texture) =>
            {
                const material = new THREE.MeshBasicMaterial({
                    map: texture,
                });
                const geometry = new THREE.CircleGeometry(buttonSize / 3, 32);
                const controlX2 = new THREE.Mesh(geometry, material);
                // controlB.position.x = -(this.width / 2) - (buttonSize / 2);
                controlX2.position.x = 0;
                controlX2.position.y = -(this.height / 1.9) - buttonSize / 2 - 3.0;
                controlX2.position.z = 3;
                controlX2.rotateY(yRot * THREE.MathUtils.DEG2RAD);
                this.model.add(controlX2);
                new Clickable(controlX2, () => this.voteSadEnd());
                this.sadButtonEnd = controlX2;
                this.sadButtonEnd.visible = false;
                const showHelp = () =>
                {
                    document.getElementById("help-text").style.display = "block";
                    document.getElementById("help-text").innerHTML = "Click if you are not relaxed";
                };
                const hideHelp = () => (document.getElementById("help-text").style.display = "none");

                new Hoverable(
                    this.sadButtonEnd,
                    () =>
                    {
                        document.body.style.cursor = "pointer";
                        this.sadButtonEnd.scale.set(1.1, 1.1, 1.1);
                        showHelp();
                    },
                    () =>
                    {
                        this.sadButtonEnd.scale.set(1.0, 1.0, 1.0);
                        hideHelp();
                    }
                );
            });
            /////////////////////////////////
            this.textureLoader.load("assets/icons/ZoneVS-meh.png", (texture) =>
            {
                const material = new THREE.MeshBasicMaterial({
                    map: texture,
                });
                const geometry = new THREE.CircleGeometry(buttonSize / 3, 32);
                const controlX3 = new THREE.Mesh(geometry, material);
                // controlB.position.x = -(this.width / 2) - (buttonSize / 2);
                controlX3.position.x = 0;
                controlX3.position.y = -(this.height / 1.9) - buttonSize / 2 - 3.0;
                controlX3.position.z = 4;
                controlX3.rotateY(yRot * THREE.MathUtils.DEG2RAD);
                this.model.add(controlX3);
                new Clickable(controlX3, () => this.voteMehEnd());
                this.mehButtonEnd = controlX3;
                this.mehButtonEnd.visible = false;
                const showHelp = () =>
                {
                    document.getElementById("help-text").style.display = "block";
                    document.getElementById("help-text").innerHTML = "Click if you feel the same";
                };
                const hideHelp = () => (document.getElementById("help-text").style.display = "none");

                new Hoverable(
                    this.mehButtonEnd,
                    () =>
                    {
                        document.body.style.cursor = "pointer";
                        this.mehButtonEnd.scale.set(1.1, 1.1, 1.1);
                        showHelp();
                    },
                    () =>
                    {
                        this.mehButtonEnd.scale.set(1.0, 1.0, 1.0);
                        hideHelp();
                    }
                );
            });
            //////
            this.textureLoader.load("assets/icons/ZoneVS-happy.png", (texture) =>
            {
                const material = new THREE.MeshBasicMaterial({
                    map: texture,
                });
                const geometry = new THREE.CircleGeometry(buttonSize / 3, 32);
                const controlX4 = new THREE.Mesh(geometry, material);
                // controlB.position.x = -(this.width / 2) - (buttonSize / 2);
                controlX4.position.x = 0;
                controlX4.position.y = -(this.height / 1.9) - buttonSize / 2 - 3.0;
                controlX4.position.z = 5;
                controlX4.rotateY(yRot * THREE.MathUtils.DEG2RAD);
                this.model.add(controlX4);
                new Clickable(controlX4, () => this.voteHappyEnd());
                this.happyButtonEnd = controlX4;
                this.happyButtonEnd.visible = false;
                const showHelp = () =>
                {
                    document.getElementById("help-text").style.display = "block";
                    document.getElementById("help-text").innerHTML = "Click if you feel relaxed";
                };
                const hideHelp = () => (document.getElementById("help-text").style.display = "none");

                new Hoverable(
                    this.happyButtonEnd,
                    () =>
                    {
                        document.body.style.cursor = "pointer";
                        this.happyButtonEnd.scale.set(1.1, 1.1, 1.1);
                        showHelp();
                    },
                    () =>
                    {
                        this.happyButtonEnd.scale.set(1.0, 1.0, 1.0);
                        hideHelp();
                    }
                );
            });
        }


/////////////////////////////////////////////
        const loader = new GLTFLoader();

        const onLoad = (gltf) => {
            this.model.add(gltf.scene)

            const texture = new THREE.VideoTexture(video);
            this.videoMaterial = new THREE.ShaderMaterial({
                fragmentShader: this.fragmentShader(),
                vertexShader: this.vertexShader(),
                uniforms: {
                    tex: { type: "t", value: texture },
                }, });

            this.setModelMaterial = (material) => {
                this.model.traverse((node) => {
                    // search the mesh's children for the face-geo
                    if (node.isMesh && node.name === videoFaceName) {
                        node.material = material;
                    }
                });
            }


            this.textureLoader.load(poster, (texture) => {
                // const material = new THREE.MeshBasicMaterial({
                //     map: texture
                // });
                const material = new THREE.ShaderMaterial({
                    fragmentShader: this.fragmentShader(),
                    vertexShader: this.vertexShader(),
                    uniforms: { tex: { type: "t", value: texture } },
                });
                this.posterMaterial = material;
                this.setModelMaterial(material);
            });
        }

        loader.load(
            // resource URL
            modelUrl,
            // called when the resource is loaded
            ( gltf ) => {
                onLoad(gltf);
            },
            // called while loading is progressing
            ( xhr ) => {
                console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
            },
            // called when loading has errors
             ( error ) => {
                console.log( 'An error happened' );
            }
        );
    }

    setMaterial = () => {

    }

    setPosition = (x, y, z) => {
        this.model.position.set(x, y, z);
    };

    setRotation = (x, y, z) => {
        this.model.rotateY(y * THREE.MathUtils.DEG2RAD);
    };

    voteSad = () => {
        //do something
        this.sadButton.scale.set(1.5, 1.5, 1.5);
        this.mehButton.visible = false;
        this.happyButton.visible = false;
    };

    voteMeh = () => {
        //do something
        this.sadButton.visible = false;
        this.mehButton.scale.set(1.5, 1.5, 1.5);
        this.happyButton.visible = false;
    };

    voteHappy = () => {
    //do something
        this.sadButton.visible = false;
        this.mehButton.visible = false;
        this.happyButton.scale.set(1.5, 1.5, 1.5);
    };

    voteSadEnd = () => {
        //do something
        this.sadButtonEnd.scale.set(1.5, 1.5, 1.5);
        this.mehButtonEnd.visible = false;
        this.happyButtonEnd.visible = false;
    };

    voteMehEnd = () => {
        //do something
        this.sadButtonEnd.visible = false;
        this.mehButtonEnd.scale.set(1.5, 1.5, 1.5);
        this.happyButtonEnd.visible = false;
    };

    voteHappyEnd = () => {
        //do something
        this.sadButtonEnd.visible = false;
        this.mehButtonEnd.visible = false;
        this.happyButtonEnd.scale.set(1.5, 1.5, 1.5);
    };




    play = (skipSend) => {
        if (!skipSend) {
            console.log("Sending custom event 1");
            Multiplayer.instance.sendCustomEvent(this.uuid, "play", true);
        }

        VideoViewerCustom.viewers.forEach((video) => {
            if(video){
                video.pause(true);
            }
        });

        this.setModelMaterial( this.videoMaterial);

        if(this.video)
            this.video.play();



        if(this.playButton)
            this.playButton.visible = false;

        if(this.pauseButton)
            this.pauseButton.visible = true;

        if(this.positionalAudio)
            this.positionalAudio.play();

        if(this.sadButton)
            this.sadButton.visible = true;
        if(this.mehButton)
            this.mehButton.visible = true;
        if(this.happyButton)
            this.happyButton.visible = true;

        if(this.sadButtonEnd)
            this.sadButtonEnd.visible = false;
        if(this.mehButtonEnd)
            this.mehButtonEnd.visible = false;
        if(this.happyButtonEnd)
            this.happyButtonEnd.visible = false;

        // RecordEvent({
        //     type: "video-play",
        //     id: this.id
        // })
    };

    pause = (skipSend) => {
        if (!skipSend) {
            Multiplayer.instance.sendCustomEvent(this.uuid, "pause", true);
        }

        this.video.pause();
        if(this.playButton)
            this.playButton.visible = true;
        if(this.pauseButton)
            this.pauseButton.visible = false;
        if(this.positionalAudio)
            this.positionalAudio.pause();

        // if(!internal){
        //     RecordEvent({
        //         type: "video-pause",
        //         id: this.id
        //     })
        // }
    };



    vertexShader() {
        return `
            varying vec2 vUv;

            void main() {
                vUv = uv;

                vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * modelViewPosition;
            }
        `;
    }


    fragmentShader() {
        return `
            uniform sampler2D tex;
            varying vec2 vUv;

            void main() {
                gl_FragColor = texture2D(tex, vUv);
            }
        `;
    }


}
